import { createAsyncThunk } from "@reduxjs/toolkit";
import { ClaimData } from "../modules/startNewClaim/ClaimData";
import axiosInstance from "./axiosInstance";

export interface PaginationSearchPayloadProps {
  data: string;
  pageNo: number;
  pageSize: number;
  isExeactMatch?:boolean;
  orderBy: string;
}

export const saveClaimData = async (claimData: ClaimData): Promise<any> => {
  try {
    const response = await axiosInstance.post("/claim/save", claimData);
    return response.data;
  } catch (error) {
    console.log("Error in API call:", error);
    throw error;
  }
};

export const getClaimList = createAsyncThunk(
  "getClaimList",
  async (payload: PaginationSearchPayloadProps, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/claim/getClaimList", payload);
      return response.data;
    } catch (error: any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getClaimDetailsById = createAsyncThunk(
  "getClaimDetailsById",
  async (claimId: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/claim/get/" + claimId);
      return response.data;
    } catch (error: any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateClaimByClaimId = createAsyncThunk(
  "updateClaimByClaimId",
  async (payload: any, { rejectWithValue }) => {
    try {
      const claimId = payload?.claimId;
      const payload1 = { ...payload };
      delete payload1["claimId"];
      const response = await axiosInstance.put(
        "/claim/updateClaim/" + claimId,
        payload
      );
      return response.data;
    } catch (error: any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getFileNumber = createAsyncThunk(
  "getNewFileNumber",
  async (claimId: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/claim/getNewFileNumber");
      return response.data;
    } catch (error: any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const checkClaimNumberPresent = createAsyncThunk(
  "checkClaimNumberPresent",
  async (claimNumber: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        "/claim/checkClaimNumberPresent/" + claimNumber
      );
      return response.data;
    } catch (error: any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const resendEmail = createAsyncThunk(
  "resendEmail",
  async (claimNumber: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        "/claim/resendEmail/" + claimNumber
      );
      debugger
      if(response.status==200){
      return response.data;
      }
    } catch (error: any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateClaimDataStatus = createAsyncThunk(
  "updateClaimDataStatus",
  async (payload: any, { rejectWithValue }) => {
    try {
      const claimId = payload.claimId;
      const claimList = payload.data.data.map((e: any) =>
        e.id == claimId ? { ...e, emailSent: true } : e
      );

      const data = {
        ...payload.data,
        data: claimList,
      };

      return data;
    } catch (error: any) {
      console.log("Error in API call:", error);
      return rejectWithValue("Something went wrong");
    }
  }
);
