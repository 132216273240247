// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the DataGrid header */
.custom-header {
  background-color: #cfdce4 !important; /* Header background color */
}

.MuiDataGrid-columnHeader {
  background-color: "#cfdce4";
}

/* Ensure that the header cells occupy full width */
.MuiDataGrid-columnHeaders {
  background-color: #cfdce4 !important; /* Consistent header color */
}

/* Style for header title text */
.MuiDataGrid-columnHeaderTitle {
  color: #000; /* Header text color */
  font-weight: bold !important; /* Optional: Make header text bold */
}

/* Style for the DataGrid rows */
.MuiDataGrid-row {
  background-color: #f7f7f7; /* Background color for grid rows */
}

/* Style for scrollbar filler */
.MuiDataGrid-scrollbarFiller {
  background-color: #cfdce4; /* Color for scrollbar filler */
}

/* Style for filler */
.MuiDataGrid-filler {
  background-color: #cfdce4; /* General filler color */
}
.css-1na3gmr-MuiDataGrid-root .MuiDataGrid-columnHeader{
  background-color: #eaeaea !important;
  font-weight: bold !important;
}`, "",{"version":3,"sources":["webpack://./src/modules/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;EACE,oCAAoC,EAAE,4BAA4B;AACpE;;AAEA;EACE,2BAA2B;AAC7B;;AAEA,mDAAmD;AACnD;EACE,oCAAoC,EAAE,4BAA4B;AACpE;;AAEA,gCAAgC;AAChC;EACE,WAAW,EAAE,sBAAsB;EACnC,4BAA4B,EAAE,oCAAoC;AACpE;;AAEA,gCAAgC;AAChC;EACE,yBAAyB,EAAE,mCAAmC;AAChE;;AAEA,+BAA+B;AAC/B;EACE,yBAAyB,EAAE,+BAA+B;AAC5D;;AAEA,qBAAqB;AACrB;EACE,yBAAyB,EAAE,yBAAyB;AACtD;AACA;EACE,oCAAoC;EACpC,4BAA4B;AAC9B","sourcesContent":["/* Styles for the DataGrid header */\n.custom-header {\n  background-color: #cfdce4 !important; /* Header background color */\n}\n\n.MuiDataGrid-columnHeader {\n  background-color: \"#cfdce4\";\n}\n\n/* Ensure that the header cells occupy full width */\n.MuiDataGrid-columnHeaders {\n  background-color: #cfdce4 !important; /* Consistent header color */\n}\n\n/* Style for header title text */\n.MuiDataGrid-columnHeaderTitle {\n  color: #000; /* Header text color */\n  font-weight: bold !important; /* Optional: Make header text bold */\n}\n\n/* Style for the DataGrid rows */\n.MuiDataGrid-row {\n  background-color: #f7f7f7; /* Background color for grid rows */\n}\n\n/* Style for scrollbar filler */\n.MuiDataGrid-scrollbarFiller {\n  background-color: #cfdce4; /* Color for scrollbar filler */\n}\n\n/* Style for filler */\n.MuiDataGrid-filler {\n  background-color: #cfdce4; /* General filler color */\n}\n.css-1na3gmr-MuiDataGrid-root .MuiDataGrid-columnHeader{\n  background-color: #eaeaea !important;\n  font-weight: bold !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
