import { createSlice } from "@reduxjs/toolkit";
import {
  nextQuestion,
  submitAnswer,
  startQuestions,
} from "../../api/chatbotApi";

interface chatbotState {
  loading: boolean;
  error: boolean;
  nextQuestionError: boolean;
  submitAnswerError: boolean;
  nextQuestionRes: any;
  submitAnswerRes: any;
  startQuestionsRes: any;
  messages? :any
}
const initialState: chatbotState = {
  loading: false,
  error: false,
  nextQuestionError: false,
  submitAnswerError: false,
  nextQuestionRes: null,
  submitAnswerRes: null,
  startQuestionsRes: null,
  messages:[]
};
const chatBotSlice = createSlice({
  name: "Chatbot",
  initialState,
  reducers: {
    resetChatBotData: (state, action) => {
      state.loading = false;
      state.error = false;
      state.nextQuestionError = false;
      state.submitAnswerError = false;
      state.nextQuestionRes = null;
      state.submitAnswerRes = null;
      state.startQuestionsRes = null;

      // return initialState;
    },
    setMessagesValue:(state,action)=>{
      console.log(state,action)
      state.messages=action.payload
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(startQuestions.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.startQuestionsRes = null;
      })
      .addCase(startQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.startQuestionsRes = action.payload;
      })
      .addCase(startQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.startQuestionsRes = action.payload;
      })
      .addCase(nextQuestion.pending, (state) => {
        state.nextQuestionRes = null;
      })
      .addCase(nextQuestion.fulfilled, (state, action) => {
        state.nextQuestionRes = action.payload;
      })
      .addCase(nextQuestion.rejected, (state, action) => {
        state.nextQuestionRes = action.payload;
      })
      .addCase(submitAnswer.pending, (state) => {
        state.submitAnswerRes = null;
      })
      .addCase(submitAnswer.fulfilled, (state, action) => {
        state.submitAnswerRes = action.payload;
      })
      .addCase(submitAnswer.rejected, (state, action) => {
        state.submitAnswerRes = action.payload;
      });
  },
});
export const { resetChatBotData,setMessagesValue} = chatBotSlice.actions;
export default chatBotSlice.reducer;
