import axios from "axios";
import BASE_URL from "./ApiUrl";

const axiosInstanceQues = axios.create({
  baseURL:BASE_URL.questionarries,
  headers: {
    // "Content-Type": "application/json",
  },
});


export default axiosInstanceQues;
