import React from "react";
import FormInput from "../../components/FormInput";
import CustomButton from "../../components/CustomButton";
interface FiltersProps {
  claimNumber: string;
  ampcusFileNumber: string;
  insuredBusinessName: string;
  assignedAccountant: string;
  clientName: string;
  setClaimNumber: (value: string) => void;
  setFileNumber: (value: string) => void;
  setBusinessNumber: (value: string) => void;
  setAssignedAccountant: (value: string) => void;
  setClientName: (value: string) => void;
  onSearch: () => void;
  onReset: () => void;
  setIsOpen:(value:boolean)=>void
}

const Filters: React.FC<FiltersProps> = ({
  claimNumber,
  ampcusFileNumber,
  insuredBusinessName,
  assignedAccountant,
  clientName,
  setClaimNumber,
  setFileNumber,
  setBusinessNumber,
  setAssignedAccountant,
  setClientName,
  onSearch,
  onReset,
  setIsOpen,
}) => {

  const filters = [
    { label: "Claim Number", value: claimNumber, setter: setClaimNumber },
    { label: "Ampcus File Number", value: ampcusFileNumber, setter: setFileNumber },
    { label: "Insured Business Name", value: insuredBusinessName, setter: setBusinessNumber },
    { label: "Assigned Accountant", value: assignedAccountant, setter: setAssignedAccountant },
    { label: "Client Name", value: clientName, setter: setClientName },
  ];

  const buttons = [
    { label: "Search", onClick: onSearch, className: "w-full sm:w-auto get__started__btn rounded-lg pl-7 pr-7 pt-4 pb-4 !text-white border-none font-semibold transition-transform transform active:scale-95 shadow-lg hover:shadow-xl" },
    { label: "Reset", onClick: onReset, className: "w-full sm:w-auto rounded-lg bg-black pl-7 pr-7 pt-4 pb-4 !text-white border-none font-semibold hover:!text-white transition-transform transform active:scale-95 shadow-lg hover:shadow-xl" },
  ];

  return (<><div className="grid grid-cols-1 p-5">
               <div className="flex justify-between items-center">
               <label className="font-semibold mb-2 text-base">Filters</label>
               <button type="button" onClick={()=>setIsOpen(false)} className="btn-close text-reset pr-3 rounded-full bg-[#cfdce4] cursor-pointer w-[4px] h-[4px] p-[14px]" data-bs-dismiss="offcanvas" aria-label="Close" id="closeButton"></button>
               </div>
            {filters.map(({ label, value, setter }, index) => {
              return (
                <div key={index} className="mb-2">
                  <label className="font-poppins text-xs font-medium mb-4">{label}</label>
                  <FormInput
                    name={label}
                    type="text"
                    className="border-2 border-[#CECECE] border-t-[#CECECE] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent
                        h-[34px]
                        "
                    value={value}
                    onChange={(e) => setter(e.target.value)}
                  />
                </div>
              );
            })}
          </div>

          <div className="flex flex-col sm:flex-row gap-3 mt-4 p-5">
            {buttons.map(({ label, onClick, className }, index) => (
              <CustomButton
                key={index}
                className={className}
                label={label}
                onClick={onClick}
              />
            ))}
          </div></>
  )
};

export default Filters;
