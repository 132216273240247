/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Interview_Tabs, timeZones } from "../../utils/constant";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import {
  clearSelection,
  updateDateTime,
  updateTimeZone,
  updateId
} from "../../store/slices/botSlice";
import { useAppSelector } from "src/store/hooks";
import CalendarWithEvents from "./CalendarWithEvents";
import TalkWithBotForm from "./TalkWithBotForm";
import SuccessPath from "src/Features/successPath";
import { getScheduleList,deleteSchedule } from "src/api/interview";
import { toast } from "react-toastify";
import { resetDeleteSchedulingValue } from "src/store/slices/interviewSlice";
interface props {
  type: string;
  siteData: any;
  callEditScheduleApi: ({
    item,
    data,
    moduleType,
  }: InterViewProps) => void;
  onClickActionButton: ({
    data,
    actionType,
    moduleType,
  }: InterViewProps) => void;
}

const TalkWithBot = ({ type, siteData,callEditScheduleApi, onClickActionButton }: props) => {
  const formValue = useAppSelector((state: RootState) => state.talkBot);
  const { 
    getScheduleListData,
    deleteScheduleError,
    deleteScheduleRes,
    editScheduleError,
    editScheduleRes
  } = useAppSelector((state: RootState) => state.interview);
  const dispatch = useDispatch();
  const dispatchForScheduleList = useDispatch<AppDispatch>();
  const [successMessage, setSuccesMessage] = useState<boolean>(false);
  const [selectedItem,setSelectedItem]= useState({})
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const handleDateTimeChange = (value: string) => {
    dispatch(updateDateTime(value));
  };

  const handleTimeZoneChange = (value: string) => {
    dispatch(updateTimeZone(value));
  };

  const handleItemIdChange = (data?: any,item?:any) => {
    setSelectedItem(item)
    dispatch(updateId(data));
  };

  const handleClearSelection = () => {
    dispatch(clearSelection());
    onClickActionButton({
      data: null,
      actionType: "RESET",
      moduleType: "TALK_WITH_BOAT",
    });
  };
  const callScheduleList = () => {
    const { email,claimNumber } = siteData;
    const payload = {
      insuredEmail:email,
      claimsId:claimNumber
    };
    dispatchForScheduleList(getScheduleList(payload));
  };

  useEffect(()=>{
    if(siteData?.email && siteData?.claimId){
      if(type !== Interview_Tabs.TALK_WITH_BOT){   callScheduleList()}
    }
  },[siteData])
  const handleSubmit = () => {
    if(formValue.id){
      callEditScheduleApi({
        item:selectedItem,
        data: { dateTime: formValue.dateTime, timeZone: formValue.timeZone,id:formValue.id  },
        moduleType:
          type === Interview_Tabs.ACCOUNTANT_CALL
            ? "TALK_WITH_ACCOUNTANT"
            : "TALK_WITH_BOAT",
      });
    }else {onClickActionButton({
      data: { dateTime: formValue.dateTime, timeZone: formValue.timeZone },
      actionType: "SUBMIT",
      moduleType:
        type === Interview_Tabs.ACCOUNTANT_CALL
          ? "TALK_WITH_ACCOUNTANT"
          : "TALK_WITH_BOAT",
    });
  }
    // setSuccesMessage(true);
  };
  const callDeleteSchedule=(id:any)=>{
    const payload = {
      interviewId:id,
      claimsId:siteData?.claimNumber
    };
    dispatchForScheduleList(deleteSchedule(payload));
  }
  const callResetValue=()=>{
    setTimeout(()=>{
      resetDeleteSchedulingValue()
    },100)
  }
  
  useEffect(()=>{
    if(deleteScheduleRes?.statusCode===200){
     toast.success(deleteScheduleRes?.message)
     callScheduleList()
     callResetValue()
    }
  },[deleteScheduleRes])
  useEffect(()=>{
    if(editScheduleRes?.statusCode===200){
     toast.success(editScheduleRes?.message)
     callScheduleList()
     callResetValue()
     handleClearSelection()
    }
  },[editScheduleRes])
  useEffect(()=>{
    if(deleteScheduleError){
      toast.error(deleteScheduleRes?.message)
      callResetValue() 
    }
    if(editScheduleError){
      toast.error(editScheduleRes?.message)
      callResetValue() 
    }

  },[deleteScheduleError,editScheduleError])
  return !successMessage ? (
    <div className="bg-[#EEEEEE] h-auto rounded-sm m-auto w-[100%] mt-4 py-2 flex justify-center">
      <div className="flex flex-col w-[70%]">
        <h2 className="text-[#009BDB] whitespace-nowrap font-poppins text-center text-base font-normal h-auto mb-2">
        When would you like to schedule the call?
        </h2>
      <div className="lg:flex md:flex sm:grid lg:justify-between md:justify-between text-sm pl-4">
        <p className="whitespace-nowrap text-sm font-medium">
          Insured Business Name: &nbsp;
          <span className="text-[#1F7FBB] font-semibold">{siteData?.buisnessName}</span>
       </p>

          <p className="whitespace-nowrap text-sm font-medium">       Claim Number: &nbsp;
            <span className="text-[#1F7FBB] font-semibold">#{siteData?.claimNumber}</span>
          </p>
        </div>

        {type === Interview_Tabs.TALK_WITH_BOT && (
          <h2 className="text-[#000]-500 font-poppins text-center text-base font-medium h-auto mt-6">
            Please call a bot at
            <span className="text-[#1F7FBB] font-semibold"> +1 (888) 470-1067</span>.
          </h2>
        )}

        {type !== Interview_Tabs.TALK_WITH_BOT && (
          <>
            <TalkWithBotForm
              formValue={formValue}
              timeZones={timeZones}
              onDateTimeChange={handleDateTimeChange}
              onTimeZoneChange={handleTimeZoneChange}
              onSubmit={handleSubmit}
              onClear={handleClearSelection}

            />
            <CalendarWithEvents
              selectedDate={selectedDate}
              events={getScheduleListData}
              handleItemIdChange={handleItemIdChange}
              onDateChange={setSelectedDate}
              callDeleteSchedule={callDeleteSchedule}
              
             
            />
          </>
        )}
      </div>
    </div>
  ) : (
    <div>
      <SuccessPath
        message="Thank you
Your call has been scheduled successfully!"
        setShow={setSuccesMessage}
      />
    </div>
  );
};
const getFormattedDate = (date: Date) => {
  return date.toISOString().split("T")[0]; // Converts to YYYY-MM-DD format
};

export default TalkWithBot;
