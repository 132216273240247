import React, { useEffect, useState } from 'react'
import VerticalLinearStepper from 'src/components/verticalStepper'
import InterviewForm from '../InterviewForm';
import SuccessMessage from '../successMessage';
import { useAppSelector } from "src/store/hooks";
import { callCategory, callQuestions } from 'src/api/questionair';
import { AppDispatch, RootState } from 'src/store';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from 'src/components/loader';
import InitialPage from '../initialPage.tsx';
interface props {
  siteData: any,
  disableButton:boolean,
  attemptInterView:(e:any)=>void
}
const Index = ({ siteData,disableButton,attemptInterView }: props) => {
  const [activeStep, setActiveStep] = useState(-1);
  const [showIntro, setShowIntro] = useState(true)
  const [activeStepName, setActiveStepName] = useState("")
  const dispatch = useDispatch<AppDispatch>();
  const { error, loading, categoryRes, questionsRes } = useAppSelector(
    (state: RootState) => state.questionnair
  );
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  const handleHideIntro = () => {
    setShowIntro(!showIntro)
  }

  useEffect(() => {
    handleReset()
    dispatch(callCategory())
    dispatch(callQuestions({ setId: "1" }))
  }, [0])

  if (error) {
    toast.error(error, {
      position: "bottom-center"
    });
  }
  useEffect(() => {
    if (categoryRes?.length > 0) {
      setActiveStepName(categoryRes[activeStep]?.categoryName)
    }
  }, [activeStep, categoryRes])
  return (
    <div className='flex justify-center gap-5 mx-auto mt-4 p-2 rounded-md shadow-md h-screen'>
      {loading && <Loader />}
      {showIntro ?
        <InitialPage handleHideIntro={handleHideIntro} disableButton={disableButton} /> :
        <>
          <div className='flex-col w-[20%] mx-auto'>
            <VerticalLinearStepper activeStep={activeStep} categoryRes={categoryRes} />
          </div>
          <div className='flex flex-col w-[80%] mx-auto'>
            {activeStep !== categoryRes?.length + 1 && <InterviewForm attemptInterView={attemptInterView} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} handleReset={handleReset}
              questionsRes={questionsRes} categoryRes={categoryRes} activeStepName={activeStepName} siteData={siteData}
            />}
            {activeStep === categoryRes?.length + 1 &&
              <SuccessMessage />
            }
          </div>
        </>
      }
    </div>
  )
}

export default Index
