/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../css/chatbot.css";
import { MicrophoneIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import EmojiPicker from "emoji-picker-react";
import PopoverOption from "./PopoverOption";
import { Button } from "@mui/material";
import AwsWelcome from "./AwsWelcome";
import { RootState } from "src/store";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { nextQuestion, startQuestions, submitAnswer } from "src/api/chatbotApi";
import { resetChatBotData } from "src/store/slices/chatBotSlice";
import { toast } from "react-toastify";
interface AwsChatbotProps {
  onSessionEnd: (sessionId: string) => void;
  siteData: urlDecodedDataProps;
}
const AwsChatbot = ({ onSessionEnd, siteData }: AwsChatbotProps) => {
  const [input, setInput] = useState("");
  const [isVisibleEmoji, setIsVisibleEmoji] = useState<boolean>(false);
  const [isActiveSession, setIsActiveSession] = useState<boolean>(false);
  const scrollToRef: any = useRef();
  const dispatch = useAppDispatch();
  const chatbotState = useAppSelector((state: RootState) => state.chatbot);
  const [messages, setMessages]= useState<Array<any>>([]);

  useEffect(() => {
    if(isActiveSession){
      if(sessionStorage.getItem("messages")){
        callNextQuestion();
      }else{
        dispatch(startQuestions({user_id:siteData?.claimNumber}));
      }

    }
  }, [isActiveSession]);
  useEffect(() => {
    if (chatbotState.startQuestionsRes && isActiveSession) {
      callNextQuestion();
    }
  }, [chatbotState.startQuestionsRes,isActiveSession]);
   useEffect(() => {
    if(chatbotState?.nextQuestionRes && isActiveSession){
      if(chatbotState?.nextQuestionRes?.question){
      let updatedMessages = [...messages,...[chatbotState?.nextQuestionRes]]
      setMessages(updatedMessages)
      }
      else{
        toast.error(chatbotState?.nextQuestionRes)
      }
    }
  }, [chatbotState?.nextQuestionRes]);
  const callNextQuestion =  () => {
    dispatch(nextQuestion({ user_id: siteData?.claimNumber }));
  };
console.log(siteData)
  useEffect(()=>{
    if(chatbotState?.submitAnswerRes){
      if(chatbotState?.submitAnswerRes?.valid){
        callNextQuestion()
      }else{
        let updatedMessages = [...messages,...[{valid:chatbotState?.submitAnswerRes?.valid,
          question:chatbotState?.submitAnswerRes?.feedback,
          question_id: messages[messages.length-1]?.question_id

        }]]
        setMessages(updatedMessages)
      }
    }
  },[chatbotState.submitAnswerRes])

 useEffect(()=>{
  return () => {
    if(messages.filter(item=>item.end_of_questions===true).length>0){
      sessionStorage.removeItem("messages")
    }else{
      sessionStorage.setItem("messages",JSON.stringify(messages))
    }
    dispatch(resetChatBotData("All"))
  };
 },[])
  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.dialogAction) {
      const actionType = lastMessage?.dialogAction?.type;
      if (actionType === "Close") {
        onSessionEnd(lastMessage?.sessionId);
      }
    }
    scrollToBottom()
  }, [messages, messages.length]);

  const handleSendMessage = async (pInput?: string ) => {
    const userInputMessage = input || pInput;
    const quesId = messages[messages.length-1]?.question_id
    const option = messages[messages.length-1]?.options
    if (!userInputMessage || !quesId) return;
    let updatedMessages = [...messages,...[{user_id:siteData.claimNumber,question_id:quesId,answer:userInputMessage}]]
    setMessages(updatedMessages)
    dispatch(submitAnswer({user_id:siteData.claimNumber,question_id:quesId,answer:userInputMessage,expected_responses:option}))
    setInput("");
  };

  const scrollToBottom = () => {
    if(document.getElementById("messagesBox")){
      let element:any = document.getElementById("messagesBox")
      element.scrollTop = element.scrollHeight+300
    }
  };

  const onEmojiClick = (emojiObject: any) => {
    setInput(input + "" + emojiObject.emoji);
    setIsVisibleEmoji(false);
  };

  if (!isActiveSession) {
    return <AwsWelcome onClick={() => setIsActiveSession(!isActiveSession)} />;
  }
  return (
    <div className="border-[#DDD] w-full border rounded-sm pb-5 h-[70vh] mt-3  ">
      <div className="chat-box h-[60vh]" id ="messagesBox">
        {messages.map((message: any, index: number) => (
          <div key={index + Math.random()} className="flex-1 ">
            {
              (message?.question   ? (
                <div className={`bot speech-bubble mt-2`}>
                 { message?.options?.length>0?null:<p>{message?.question}</p>}
                </div>
              ) : message?.answer  && (
                <div className="flex justify-between mt-2">
                  <div />
                  <div
                    className={`${message?.answer} speech-bubble-right justify-end content-start self-end`}
                  >
                    <p>{message?.answer}</p>
                  
                  </div>
                </div>
              ))}
            <div className="w-fit flex items-center">
              {message?.options?.length>0  && (
                <div className="botactioncontainer speech-bubble">
                  <p className="button-container-title ">
                    {message?.question}
                  </p>
                  <div className="">
                    {message?.options?.map(
                      (buttonMetaData: any, index: number) => (
                        <button
                          key={index + "button"}
                          value={buttonMetaData}
                          className="capitalize"
                          onClick={() => {
                            handleSendMessage(buttonMetaData);
                          }}
                        >
                          {buttonMetaData}
                        </button>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
        <div style={{}} ref={scrollToRef}></div>
      </div>
      <div className="flex absolute z-40 bottom-[23vh] left-[35vh]">
        <EmojiPicker
          onReactionClick={onEmojiClick}
          open={isVisibleEmoji}
          onEmojiClick={onEmojiClick}
        />
      </div>

      <div className="flex mt-4  self-end justify-end ">
        {/* <div className="flex justify-center w-12 h-12 bg-[#fff] rounded-full ">
          <Button>
            <FaceSmileIcon
              onClick={() => setIsVisibleEmoji(true)}
              className="w-7 h-7 self-center text-[#EDCB65]"
            />
          </Button>
        </div> */}
        <div className="flex justify-center w-12 h-12  border-[1px]  rounded-full ">
          <PopoverOption />
        </div>
        <input
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSendMessage();
          }}
          // style={{borderWidth:1,borderColor:'#DDD',height:20}}
          className="chatbot-action-input sm:w-full md:w-full w-[100%] lg:w-[50%] border-[#2c1717] mr-4 ml-2 p-2"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
        />
        <div className="flex justify-center w-12 h-12  border-[2px]  rounded-full ">
          <Button>
            <MicrophoneIcon className="w-6 h-6 self-center" />
          </Button>
        </div>
        <Button
          className="!bg-[#207FBB] !mr-6 !ml-3"
          onClick={() => handleSendMessage()}
        >
          <PaperAirplaneIcon width={20} height={20} color="#FFF" />
        </Button>
      </div>
    </div>
  );
};

export default AwsChatbot;
