import DateTimePicker from "../../components/DateTimePicker";
import CustomButton from "../../components/CustomButton";
import { cx } from "class-variance-authority";
import React, { useState } from "react";
import Select from "react-select";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import time from "../../../src/assets/images/time.png"
import { Checkbox } from "@material-tailwind/react";

interface TalkWithBotFormProps {
  formValue: any;
  timeZones: any[];
  disableButton: boolean;
  onDateTimeChange: (value: string) => void;
  onTimeZoneChange: (value: string) => void;
  onDateChange:(value: string) => void;
  onTimeChange:(value: string) => void;
  onSubmit: () => void;
  onClear: () => void;
}

const TalkWithBotForm: React.FC<TalkWithBotFormProps> = ({
  formValue,
  timeZones,
  onDateTimeChange,
  onTimeZoneChange,
  onDateChange,
  onTimeChange,
  onSubmit,
  onClear,
  disableButton,
}) => {
  const [country, setCountry] = useState("");

  const timeZoneOptions = timeZones.map((zone) => ({
    label: `${zone.name} (${zone.abbreviation}) - ${zone.utcOffset}`,
    value: zone.name,
    country: zone.value,
  }));

  return (
    <>
      <form className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-2 py-4 px-4 w-full ">
        <div className="flex flex-col">
          <label className="text-[#000]-500 font-poppins text-sm font-medium mb-1 ">
            Select Time Zone
          </label>
          <Select
            options={timeZoneOptions}
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            name="timeZone"
            onChange={(selectedOption) => {
              onTimeZoneChange(selectedOption ? selectedOption.value : "");
              setCountry(selectedOption?.country);
            }}
            isDisabled={formValue.id === "" ? disableButton : false}
            value={
              timeZoneOptions.find(
                (option) => option.value === formValue.timeZone
              ) || null
            }
          />
          <label className="text-[#000]-500 font-poppins text-sm font-medium mt-2 ">
            Select Date
          </label>
          <DayPicker mode="single" selected={formValue.date} onSelect={(value:any)=>onDateChange(value)} />
        </div>
        <div className="flex flex-col">
          <label className="text-[#000]-500 font-poppins text-sm font-medium mb-1 ">
            Select Time  
          </label>
          <div className="bg-white h-[385px] p-2">
            <div className="border-2 border-grey h-12 flex text-[#797979] rounded-md">
              <img src={time} className="ml-2 w-8 h-8 mt-1"/>{" "} <span className="px-4 py-2">10:00 AM - 10:15 AM</span>
            </div>
          </div>
          {/* <DateTimePicker
            value={formValue.dateTime}
            onChange={onDateTimeChange}
            minDateTime={new Date()}
            disabled={formValue.id === "" ? disableButton : false}
            showTime={true}
            timezone={country}
          /> */}
        </div>
        <div className="flex flex-col">
        <label className="text-[#000]-500 font-poppins text-sm font-medium mb-1 ">
            Scheduled Interview  
          </label>
          <div className="bg-white h-[385px] p-2">
            <div className="grid">
              <span className="text-black text-sm font-semibold">Date:</span>
              <span className="text-[#A7A7A7] text-sm">Wednesday, 1 Jan 2025</span>
            </div>
            <div className="grid mt-2">
              <span className="text-black text-sm font-semibold">Time:</span>
              <span className="text-[#A7A7A7] text-sm">10:00 AM</span>
            </div>
            <div className="grid mt-2">
              <span className="text-black text-sm font-semibold">TimeZone:</span>
              <span className="text-[#A7A7A7] text-sm">Eastern standard time</span>
            </div>


          </div>
        </div>
      </form>
      <div className=" mt-2 items-center">
      <p className=" pl-4 text-sm font-semibold pt-3">
           <span className="!text-[#000]">Are you unable to make the time slots available? </span></p>
           <p className="text-[#00] pl-4 text-sm font-semibold pt-3">
           <span className="!text-[#f50404]">  You can send us an e-mail at support@ampcusforensics.com </span> to our team & we will schedule with you directly.
                  </p>
      </div>
       <div className="flex mt-2 items-center pl-4">
                    <span className="font-bold text-[#045373]">
                    Do you want to proceed  this above selection ?
                    </span>
                    <Checkbox
                      className="h-8 w-8 border-2 border-black"
                      
                    />
                  </div>
      <div className="flex lg:flex-row md:flex-row sm:flex-row gap-2 py-4 px-4 justify-center">
        <CustomButton
          className={cx(
            "bg-[#1F7FBB]",
            "border-[#1F7FBB]",
            "justify-center",
            "shadow-md shadow-[#1F7FBB]",
            "w-[150px]"
          )}
          disabled={formValue.id === "" ? disableButton : false}
          label="Submit"
          onClick={onSubmit}
        />

        <CustomButton
          className="w-[150px] bg-[#fff] shadow-md shadow-[#fff] sm:w-auto rounded-lg pl-7 pr-7 pt-4 pb-4 bg-black !text-white border-none font-semibold hover:!text-white transition-transform transform active:scale-95 shadow-xl hover:shadow-xl"
          label="Reset"
          onClick={onClear}
        />
      </div>
    </>
  );
};

export default TalkWithBotForm;
