import { useAppDispatch, useAppSelector } from "src/store/hooks";
import FormInput from "../../../components/FormInput";
import { RootState } from "src/store";
import { setInsuredInfo } from "src/store/slices/claimSlice";
import { emailValidation } from "src/utils";
import { Autocomplete } from "@react-google-maps/api";
import { useRef } from "react";

const InsuredInformation: React.FC<{ claim: any | null; isEditMode: boolean, }> = ({ isEditMode }) => {

    const { insuredInfo,claimDetails } = useAppSelector((state: RootState) => state.claim);
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
    const { insuredFirstName, insuredLastName, insuredTitle, insuredBusinessName, insuredPhoneNumber, insuredEmail, insuredPostalAddress, insuredLandmark, insuredCity, insuredState, insuredCountry, insuredPostalCode } = insuredInfo
    const dispatch = useAppDispatch()
    const handleInputChange = (event: any, key: any) => {
        const value = event.target.value;
        const reg = /^[0-9\b]+$/
        if (key === "insuredPhoneNumber") {
            if (reg.test(value) || value === "")
                dispatch(setInsuredInfo({ ...insuredInfo, [key]: value }));
        } else {
            dispatch(setInsuredInfo({ ...insuredInfo, [key]: value }));
        }
    }

    const handlePlaceSelect = () => {
        const place = autocompleteRef.current?.getPlace();
        if (!place || !place.address_components) return;

        const addressComponents = place.address_components;

        const getComponent = (type: string) =>
            addressComponents.find((component) => component.types.includes(type))?.long_name || "";

        const postalAddress = place.formatted_address || "";
        const landmark = getComponent("sublocality") || getComponent("neighborhood") || "";
        const city = getComponent("locality") || "";
        const state = getComponent("administrative_area_level_1") || "";
        const country = getComponent("country") || "";
        const pincode = getComponent("postal_code") || "";
        const placeId = place.place_id || "";

        dispatch(
            setInsuredInfo({
                ...insuredInfo,
                insuredPostalAddress: postalAddress,
                insuredLandmark: landmark,
                insuredCity: city,
                insuredState: state,
                insuredCountry: country,
                insuredPostalCode: pincode,
                placeId: placeId,
            })
        );
    };

    const insuredData = [
        { label: "Insured First Name", value: insuredFirstName, key: "insuredFirstName", required: true },
        { label: "Insured Last Name", value: insuredLastName, key: "insuredLastName", required: true },
        { label: "Insured Title", value: insuredTitle, key: "insuredTitle", required: false },
        { label: "Insured Business Name", value: insuredBusinessName, key: "insuredBusinessName", required: true },
        { label: "Insured Phone Number", value: insuredPhoneNumber, key: "insuredPhoneNumber", required: true },
        { label: "Insured Email Address", value: insuredEmail, key: "insuredEmail", required: true ,disabled:claimDetails?.emailSent},
        { label: "Insured Business Address", value: insuredPostalAddress, key: "insuredPostalAddress", required: true },
        // { label: "Landmark", value: insuredLandmark, key: "insuredLandmark", required: false },
        { label: "City", value: insuredCity, key: "insuredCity", required: true },
        { label: "State", value: insuredState, key: "insuredState", required: true },
        { label: "Country", value: insuredCountry, key: "insuredCountry", required: true },
        { label: "Postal Code", value: insuredPostalCode, key: "insuredPostalCode", required: true },
    ]


    return (
        <>
            <div className="mt-2">
                <span className="font-bold text-[#045373]">Insured Information</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2 mt-2">
                {insuredData.map(({ label, value, key, required ,disabled}) => (
                    <div key={label} className="flex flex-col gap-1">
                        <span className="flex font-medium text-sm">
                            {label}
                            {required && <span className="text-red-500"> *</span>}
                        </span>

                        {isEditMode ? (
                            <>
                                {key === "insuredPostalAddress" ? (
                                    <Autocomplete
                                        onLoad={(ref) => (autocompleteRef.current = ref)}
                                        onPlaceChanged={handlePlaceSelect}
                                    >
                                        <FormInput
                                            name="insuredPostalAddress"
                                            // label="Insured Business Address"
                                            required={true}
                                            type="text"
                                            className="mt-1 h-[34px] border-2 border-[#CECECE] border-t-[#CECECE] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent"
                                            value={insuredInfo?.insuredPostalAddress || ""}
                                            onChange={(event) => handleInputChange(event, key)}
                                        />
                                    </Autocomplete>
                                ) : (
                                    <FormInput
                                        name={label}
                                        required={required}
                                        type="text"
                                        className="mt-1 border-2 h-[34px] border-[#CECECE] border-t-[#CECECE] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent"
                                        value={value}
                                        maxLength={key === "insuredPhoneNumber" ? 10 : 100}
                                        onChange={(event) => handleInputChange(event, key)}
                                        // disabled={ key==="insuredEmail"? checkIsDisabled():false}
                                    />
                                )}
                                {key === "insuredEmail" && !emailValidation(insuredInfo?.["insuredEmail"]) && <span className="text-red-700 text-xs"> Enter valid email </span>}
                            </>
                        ) : (
                            <span className="font-medium whitespace-nowrap text-sm text-gray-700 text-wrap">{value}</span>
                        )}
                    </div>
                ))}

            </div>
        </>
    );
};

export default InsuredInformation;