import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ClientInformation from "./ClientInformation";
import ClaimInformation from "./ClaimInformation";
import InsuredInformation from "./InsuredInformation";
import Header from "./Header";
import Footer from "./Footer";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { RootState } from "src/store";
import { getClaimDetailsById } from "src/api/claimApi";
import Loader from "src/components/loader";
import {
  setClaimInfo,
  setClientInfo,
  setInsuredInfo,
} from "src/store/slices/claimSlice";
import { useLocation, useNavigate } from "react-router-dom";

const ClaimDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const claim = location.state?.claim;
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useAppDispatch();
  const { claimDetails, loading } = useAppSelector(
    (state: RootState) => state.claim
  );

  useEffect(() => {
    if (!claimDetails) return;
    const {
      claimNumber,
      fileNumber,
      assignee,
      dateOfLoss,
      status,
      assigneeEmail,
      comments,
      insuredFirstName,
      insuredLastName,
      insuredTitle,
      insuredBusinessName,
      insuredPostalAddress,
      insuredPhoneNumber,
      insuredEmail,
      requiredOtherInfo,
    }: any = claimDetails;

    const claimData = {
      fileNumber: fileNumber,
      claimNumber: claimNumber,
      assignee: assignee,
      assigneeEmail: assigneeEmail,
      dateOfLoss: dateOfLoss,
      comments: comments,
      status: status,
    };

    const insuredInfo = {
      insuredFirstName: insuredFirstName,
      insuredLastName:insuredLastName,
      insuredTitle: insuredTitle,
      insuredBusinessName: insuredBusinessName,
      insuredPostalAddress: insuredPostalAddress,
      insuredPhoneNumber: insuredPhoneNumber,
      insuredEmail: insuredEmail,
      requiredOtherInfo: requiredOtherInfo,
    };

    const {
      clientName,
      salutation,
      clientCompany,
      phoneNumber,
      emailAddress,
      firstName,
      lastName,
      middleName,
    } = claimDetails?.clientDetails;
    const clientInfo: any = {
      clientName: clientName,
      clientTitle: salutation,
      salutation: salutation,
      clientCompany: clientCompany,
      phoneNumber: phoneNumber,
      email: emailAddress,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
    };
    dispatch(setClaimInfo(claimData));
    dispatch(setClientInfo(clientInfo));
    dispatch(setInsuredInfo(insuredInfo));
  }, [claimDetails]);

  useEffect(() => {
    dispatch(getClaimDetailsById(claim.id));
  }, [claim]);

  const toggleEditView = () => {
    setIsEditMode((prevState) => !prevState);
  };

  if (!claim) {
    return null;
  }

  return (
    <div className="relative">
      <div
        className="flex gap-2 items-center cursor-pointer text-xl"
    
      >
        <span className="font-semibold" onClick={() => navigate("/dashboard")}>
        <KeyboardBackspaceIcon fontSize="large" />
          
          Back</span>
      </div>

      <Header isEditMode={isEditMode} toggleEditView={toggleEditView} />
      {loading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-10 gap-5 h-auto lg:h-[100vh]">
          <div className="lg:col-span-3 bg-[#f7f7f7] p-5">
            <ClientInformation client={claimDetails?.clientDetails} />
          </div>

          <div className="lg:col-span-6 p-5 ">
            <ClaimInformation claim={claimDetails} isEditMode={isEditMode} />
            <InsuredInformation claim={claimDetails} isEditMode={isEditMode} />
            <Footer
              claim={claimDetails}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClaimDetails;
