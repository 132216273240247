/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Tabs from "../../modules/InterviewForAdjuster/Tabs";
import { useState } from "react";
import { Interview_Tabs } from "../../utils/constant";
import AwsChatbot from "../../modules/aws-lex/AwsChatbot";
import TalkWithBot from "../../modules/talkWithBot";
import Interview from "../../modules/InterviewForAdjuster/interview/index";
import CryptoJS from "crypto-js";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { RootState } from "src/store";
import { toast } from "react-toastify";
import Loader from "src/components/loader";
import SuccessPath from "src/Features/successPath";
import { clearSelection } from "src/store/slices/botSlice";
import {
  attemptInterview,
  editSchedule,
  scheduleInterview,
  checkIsAttempted,
} from "src/api/interview";
import { saveTranscriptDocumets } from "src/api/documentApi";
import { setMessagesValue } from "src/store/slices/chatBotSlice";
import InitialPage from "src/modules/InterviewForAdjuster/scheduleInterview/initialPage";
import arrowBack from "../../../src/assets/images/Left Arrow.png";
// import { resetChatBotData, setMessagesValue } from "src/store/slices/chatBotSlice";
// import { DialogCustomAnimation } from "src/components/dialog";
const StartInterviewForAdjuster = (props?: any) => {
  const [siteData, setSiteData] = useState<any>();
  // const [readyToRender,setReadyToRender] = useState<string>("In Progress");
  const { data } = useParams<string>();
  const dispatch = useAppDispatch<any>();
  const [successMessage, setSuccesMessage] = useState<boolean>(false);
  const [timeZones, setTimeZones] = useState<TimeZone[]>([]);
  // const [open,setOpen]=useState(false)
  const {
    error,
    message,
    loading,
    checkIsAttemptedLoading,
    checkIsAttemptedRes,
    interviewScheduledRes,
  } = useAppSelector((state: RootState) => state.interview);

  const {
    loading: docLoading,
    message: docMessage,
    error: docError,
  } = useAppSelector((state: RootState) => state.documents);

  React.useEffect(() => {
    if (data && !siteData) {
      decrypt1();
    }
    // else {
    //   setReadyToRender("URL is not valid");
    // }
  }, [data]);

  React.useEffect(() => {
    if (siteData) {
      dispatch(checkIsAttempted({ claimId: siteData.claimId }));
    }
  }, [siteData]);

  const attemptInterViewOnClick = () => {
    const { authToken, claimNumber, tempPassword } = siteData;
    const payload: attemptInterview = {
      authToken: authToken,
      claimsId: claimNumber,
      tempPassword: tempPassword,
    };
    dispatch(attemptInterview(payload));
  };

  React.useEffect(() => {
    fetch("/timezones.json")
      .then((response) => response.json())
      .then((data) => setTimeZones(data))
      .catch((error) => console.error("Error loading timezones:", error));
  }, []);

  // React.useEffect(()=>{
  //   if(interviewScheduledRes?.statusCode === 200){
  //    if (siteData && siteData.authToken) {
  //     attemptInterView(siteData);
  //     setReadyToRender("Completed");
  //   } else {
  //     setReadyToRender("URL information is not valid");
  //   }
  //   }
  // },[interviewScheduledRes,siteData])

  React.useEffect(() => {
    if (error && message) {
      toast.error(message);
      // setOpen(true)
    } else if (!error && message) {
      setSuccesMessage(true);
      // setOpen(false)
      toast.success(message);
    }
  }, [message, error]);

  React.useEffect(() => {
    if (docError && docMessage) {
      toast.error(docMessage);
      // setOpen(true)
    } else if (!docError && docMessage) {
      setSuccesMessage(true);
      // setOpen(false)
      toast.success(docMessage);
    }
  }, [docMessage, docError]);

  const decrypt1 = () => {
    try {
      var finalData = data ? decodeURIComponent(data) : "";
      var base64EncodedKeyFromJava = "QUJDREVGR0hJSktMTU5PUA==";
      var keyForCryptoJS = CryptoJS.enc.Base64.parse(base64EncodedKeyFromJava);
      var decodeBase64 = CryptoJS.enc.Base64.parse(finalData);

      var decryptedData = CryptoJS.AES.decrypt(
        {
          ciphertext: decodeBase64,
        },
        keyForCryptoJS,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      var decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);

      let oldMsg: any = localStorage.getItem("CHAT_BOT_DATA");
      const newSiteData = JSON.parse(decryptedText);

      if (oldMsg !== "") {
        oldMsg = JSON.parse(oldMsg);
        if (oldMsg?.claimId != newSiteData?.claimId) {
          localStorage.setItem("CHAT_BOT_DATA", JSON.stringify(""));
          dispatch(setMessagesValue(null));
        }
      }

      setSiteData(newSiteData);
    } catch (e) {
      // setReadyToRender("URL information is not valid");
    }
  };

  const [activeTab, setActiveTab] = useState<any>("");

  // React.useEffect(() => {
  //   if (checkIsAttemptedRes && checkIsAttemptedRes?.data) {
  //     setActiveTab(Interview_Tabs.ACCOUNTANT_CALL)
  //   } else if (!docError && docMessage) {
  //     setActiveTab(Interview_Tabs.CHAT_WITH_BOT)
  //   }
  // }, [checkIsAttemptedRes ]);

  const onClickActionButton = ({
    data,
    actionType,
    moduleType,
  }: InterViewProps) => {
    if (actionType === "SUBMIT") {
      const { dateTime, timeZone } = data;
      let errorFileds = "";
      if (!dateTime) {
        errorFileds += " Date & Time,";
      }
      if (timeZone?.trim()?.length === 0) {
        errorFileds += " Time Zone";
      }
      if (errorFileds) {
        toast.error("Please select " + errorFileds);
        return;
      }
    }

    if (moduleType === "TALK_WITH_BOAT") {
      if (actionType === "SUBMIT") {
        callScheduleApi({ data, moduleType });
      }
    } else if (moduleType === "TALK_WITH_ACCOUNTANT") {
      if (actionType === "SUBMIT") {
        callScheduleApi({ data, moduleType });
      }
    }
  };

  const getSchudalFormattedDate = (date: Date, timeZone: string) => {
    const ianaTimeZone = timeZone;
    if (!ianaTimeZone) {
      throw new Error(`Invalid time zone: ${timeZone}`);
    }

    // Format the date in the selected time zones
    const formatter = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: ianaTimeZone,
    });

    const [
      { value: month },
      ,
      { value: day },
      ,
      { value: year },
      ,
      { value: hour },
      ,
      { value: minute },
    ] = formatter.formatToParts(date);

    const finalDate = `${year}-${month}-${day}T${hour}:${minute}:00`;

    return finalDate;
  };

  const callScheduleApi = ({ data, moduleType }: InterViewProps) => {
    const { dateTime, timeZone } = data;
    const timeZoneData = timeZones.find((zone) => zone.name === timeZone);

    if (!timeZoneData) {
      console.error("Timezone not found in the provided data");
      return;
    }

    if (!siteData) return;

    const {
      name,
      authToken,
      claimNumber,
      email,
      forensicAssignee,
      insuredBusinessName,
      clientFirstName,
      clientLastName,
    }: urlDecodedDataProps = siteData;

    // 2024-10-31T14:00:00
    const start_date = new Date(dateTime);
    const end_date = new Date(start_date.getTime() + 45 * 60000);
    const finalStartDate = getSchudalFormattedDate(
      start_date,
      timeZoneData.value
    );
    const finalEndDate = getSchudalFormattedDate(end_date, timeZoneData.value);
    const payload: InterViewSchedule = {
      interviewType: moduleType,
      insuredEmail: email,
      // interviewScheduledWithEmail: "string",
      // insuredPhone: "string",
      // countryCode: "string",
      insuredName: name,
      forensicAssignee: forensicAssignee,
      // ccEmailId: "string",
      interviewStartTime: finalStartDate,
      interviewEndTime: finalEndDate,
      timezone: timeZone,
      interviewStatus: "DRAFT",
      authToken: authToken,
      // meetingId: "string",
      // meetingPasscode: "string",
      // meetingLink: "string",
      claimsId: claimNumber,
      insuredBusinessName: insuredBusinessName,
      clientFirstName: clientFirstName,
      clientLastName: clientLastName,
    };
    // attemptInterViewOnClick()
    dispatch(scheduleInterview(payload));
  };

  const handleEditSchedule = async ({
    item,
    data,
    moduleType,
  }: InterViewProps) => {
    try {
      await editScheduleApi({ item, data, moduleType }); // Ensure it's correctly referenced
    } catch (error) {
      console.error("Error calling edit schedule API:", error);
    }
  };

  const editScheduleApi = ({ item, data }: InterViewProps) => {
    const { dateTime, timeZone } = data;
    const timeZoneData = timeZones.find((zone) => zone.name === timeZone);

    if (!timeZoneData) {
      console.error("Timezone not found in the provided data");
      return;
    }

    if (!siteData) return;

    const { authToken }: urlDecodedDataProps = siteData;
    // 2024-10-31T14:00:00
    const start_date = new Date(dateTime);
    const end_date = new Date(start_date.getTime() + 45 * 60000);
    const finalStartDate = getSchudalFormattedDate(
      start_date,
      timeZoneData.value
    );
    const finalEndDate = getSchudalFormattedDate(end_date, timeZoneData.value);
    const payload: InterViewSchedule = {
      id: item.id,
      insuredEmail: item.insuredEmail,
      interviewScheduledWithEmail: item.interviewScheduledWithEmail,
      insuredPhone: item.insuredPhone,
      countryCode: item.countryCode,
      insuredName: item.insuredName,
      ccEmailId: item.ccEmailId,
      interviewType: item.interviewType,
      interviewStartTime: finalStartDate,
      interviewEndTime: finalEndDate,
      timezone: timeZone,
      interviewStatus: item.interviewStatus,
      authToken: authToken,
      claimsId: item.claimsId,
    };
    dispatch(editSchedule(payload));
  };

  const _onSessionEnd = async (lastMessageObj?: any) => {
    if (!siteData) return;
    const { authToken, claimId }: urlDecodedDataProps = siteData;
    let fileName = lastMessageObj.transcript_link?.split("/");
    if (fileName) {
      fileName = fileName[fileName.length - 1];
    }
    const payload: any = {
      authToken: authToken,
      claimId: claimId,
      downloadUrl: lastMessageObj.transcript_link, //claimNumber + "_session_attributes.xlsx",
      cloud: "AWS",
      type: "Transcript",
      filename: fileName,
      transcriptOneDriveFileId: lastMessageObj.transcript_file_id, //will get in the response of chatbot
    };
    // attemptInterViewOnClick();
    dispatch(saveTranscriptDocumets(payload));
    // dispatch(checkIsAttempted({ claimId: siteData.claimId }));
  };

  React.useEffect(() => {
    if (checkIsAttemptedRes?.data?.attempt === 0) {
      setSuccesMessage(false);

      dispatch(clearSelection());
    }
  }, [activeTab]);

  React.useEffect(() => {
    if (siteData) {
      dispatch(checkIsAttempted(siteData));
    }
  }, [siteData]);

  return (
    <div className=" mx-auto">
      {(loading || docLoading || checkIsAttemptedLoading) && <Loader />}
      {activeTab === "" && (
        <InitialPage
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          {...props}
        />
      )}
      {/* {(Interview_Tabs.TALK_WITH_BOT === activeTab || Interview_Tabs.ACCOUNTANT_CALL === activeTab) &&    
         <div className="lg:flex md:flex sm:grid lg:justify-between md:justify-between text-sm pl-4 mb-2 ml-[10%] mr-[10%]">
        <p className="whitespace-nowrap text-sm font-medium">
          Insured Business Name: &nbsp;
          <span className="text-[#1F7FBB] font-semibold">{siteData?.buisnessName}</span>
        </p>

          <p className="whitespace-nowrap text-sm font-medium">       Claim Number: &nbsp;
            <span className="text-[#1F7FBB] font-semibold">#{siteData?.claimNumber}</span>
          </p>
        </div>}
        <Tabs setActiveTab={setActiveTab} activeTab={activeTab} {...props} 
        /> */}
      {activeTab !== "" && (
        <div className="flex justify-between">
          {" "}
          <div className="block pl-6">
            <div
              className="flex mb-2 text-base font-semibold cursor-pointer"
              onClick={() => setActiveTab("")}
            >
              {" "}
              <img src={arrowBack} width={"40px"} /> Back
            </div>
            <p className="font-semibold text-2xl  g ml-2 whitespace-nowrap">
              {activeTab}
            </p>
            <div className="line rounded-xl w-[500px]"></div>
          </div>
          <div className="grid sm:grid text-sm pl-4 mb-2 ml-[10%] mr-[10%]">
            <p className="whitespace-nowrap text-sm font-medium">
              Insured Business Name: &nbsp;
              <span className="text-[#1F7FBB] font-semibold">
                {siteData?.buisnessName}
              </span>
            </p>

            <p className="whitespace-nowrap text-sm font-medium">
              {" "}
              Claim Number: &nbsp;
              <span className="text-[#1F7FBB] font-semibold">
                #{siteData?.claimNumber}
              </span>
            </p>
          </div>
        </div>
      )}
      {Interview_Tabs.CHAT_WITH_BOT === activeTab &&
        (!successMessage && checkIsAttemptedRes?.data?.attempt===0 ? (
          <AwsChatbot
            onSessionEnd={_onSessionEnd}
            siteData={siteData}
            disableButton={checkIsAttemptedRes?.data?.attempt>0?true:false}
          />
        ) : checkIsAttemptedRes?.data?.attempt!==0 ? (
          <SuccessPath
            message="Your details have been successfully submitted. Our accountant will review the information, and you can expect an email shortly outlining the documentation required to evaluate your claim."
            setShow={successMessage}
          />
        ) : (
          <SuccessPath
            message="Your information will be sent to an accountant and they will be sending a request for information to you detailing all the documentation needed to evaluate your claim via an email."
            setShow={successMessage}
          />
        ))}
      {Interview_Tabs.TALK_WITH_BOT === activeTab && (
        <>
          {!successMessage ? (
            <TalkWithBot
              disableButton={checkIsAttemptedRes?.data?.attempt>0?true:false}
              onClickActionButton={onClickActionButton}
              type={Interview_Tabs.TALK_WITH_BOT}
              siteData={siteData}
              callEditScheduleApi={handleEditSchedule}
              {...props}
            />
          ) : (
            <SuccessPath
              message="Your details has been recorded and team will revert back to you with additional information needed."
              setShow={successMessage}
            />
          )}
        </>
      )}
      {Interview_Tabs.QUESTIONNAIRE === activeTab && (
        <Interview
        disableButton={checkIsAttemptedRes?.data?.attempt>0?true:false}
          type={Interview_Tabs.QUESTIONNAIRE}
          {...props}
          siteData={siteData}
          attemptInterView={() => attemptInterViewOnClick()}
        />
      )}

      {Interview_Tabs.ACCOUNTANT_CALL === activeTab && (
        <React.Fragment>
          {!successMessage ? (
            <TalkWithBot
              onClickActionButton={onClickActionButton}
              disableButton={checkIsAttemptedRes?.data?.attempt>0?true:false}
              type={Interview_Tabs.ACCOUNTANT_CALL}
              siteData={siteData}
              setActiveTab={setActiveTab}
              callEditScheduleApi={handleEditSchedule}
              {...props}
            />
          ) : (
            <SuccessPath
              message="Your interview is scheduled successfully"
              // message="Your information will be sent to an accountant and they will be sending a request for information to you detailing all the documentation needed to evaluate your claim via an email or a phone call"
              setShow={successMessage}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );

  // } else {
  //   if (readyToRender === "In Progress") {
  //   } else {
  //     return <div className="container mx-auto">{readyToRender}</div>;
  //   }
  // }
};
export default StartInterviewForAdjuster;
