import React, { useEffect, useMemo } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CalendarWithEvents.css";
import CardWithMenu from "./CardWithMenu";
import moment from "moment";
import Index from "../InterviewForAdjuster/interview";

interface CalendarWithEventsProps {
    selectedDate: Date | null;
    events: any;
    onDateChange: (date: any | any[]) => void;
    callDeleteSchedule:(id:any)=>void
    handleItemIdChange: (data: any,item?:any ) => void;
    setActiveTab:()=>void;

}

const formatDate = (date: Date | null) => {
    if (!date) return "";
    return date.toLocaleDateString("en-GB", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric"
    });
};

const formatDateForEvents = (date: Date | null) => {
    return date ? moment(moment(date).toISOString()).format("YYYY-MM-DD"):""
};

const CalendarWithEvents: React.FC<CalendarWithEventsProps> = ({
    selectedDate,
    events,
    onDateChange,
    callDeleteSchedule,
    handleItemIdChange,
    setActiveTab
}) => {

    const tileContent = ({ date }: { date: Date }) => {
        const dateString = formatDateForEvents(date);
        return events?.length>0 && events?.map((eve:any)=>( eve[dateString]?.length ? (
            <div className="event-dot"></div>
        ):null)) ;
    };
 
  
    return (
        <div className=" flex border border-gray-200 rounded-lg overflow-hidden mt-4">
            {/* <div className="w-1/2 p-4 bg-white">
                <Calendar
                    onChange={(ee)=> onDateChange(ee)}
                    value={selectedDate}
                    view="month"
                    calendarType="gregory"
                    className="border-0 custom-calendar"
                    formatShortWeekday={(locale:any, date:any) =>
                        date.toLocaleDateString(locale, { weekday: "narrow" })
                    }
                    tileContent={tileContent}
                    prev2Label={null}
                    next2Label={null}
                    prevLabel="◀"
                    nextLabel="▶"
                />
            </div> */}
            <div className="bg-[#EEEEEE] w-full p-4 border-l border-gray-200 h-[300px]">
                <h3 className="font-semibold text-lg mb-2 font-poppins">
                    {/* Events on {formatDate(selectedDate)} */}
                    Schedule Interview 
                </h3>
           
                <ul>
                    {events?.length>0?
                    events?.map((eve:any,)=>(
                        eve[formatDateForEvents(new Date(Object.keys(eve)[0]))]?.length ? (
                            eve[formatDateForEvents(new Date(Object.keys(eve)[0]))].map((item:any, index :number) => (
                                <CardWithMenu item={item} selectedDate={selectedDate} key={index}
                                handleItemIdChange={handleItemIdChange}
                                callDeleteSchedule={callDeleteSchedule}
                                setActiveTab={setActiveTab}
                          
                                />
                          
                        )))
                        :
                        (
                           <p className="text-sm text-gray-500"></p>
                       )
                    
                    )):
                    (
                        <p className="text-sm text-gray-500">No events for this day.</p>
                    )
                }
                </ul>
            </div>
        </div>
    )
};

export default CalendarWithEvents;
