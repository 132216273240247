import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
// import ClaimDetailsLogo from "components/icons/ClaimDetailsLogo";
// import StartInterviewLogo from "../../components/icons/StartInterviewLogo";
// import ToolsLogo from "../../components/icons/ToolsLogo";
import logo from "../../assets/images/final-logo.png";
import DashboardLogo from "../../components/icons/DashboardLogo";
import ClaimDetailsLogo from "../../components/icons/ClaimDetailsLogo";
import { useLocation } from 'react-router-dom';
interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const triggerRef = useRef<any>(null);
  const sidebarRef = useRef<any>(null);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [sidebarExpanded] = useState(
    localStorage.getItem("sidebar-expanded") === "true"
  );
  const location = useLocation();
  useSidebarEffects(sidebarOpen, setSidebarOpen, sidebarRef, triggerRef);
  useSidebarExpandedEffect(sidebarExpanded);
  const navItems = [
    { to: "/dashboard", label: "Dashboard", Icon: DashboardLogo },
    // { to: "", label: "Start Interview", Icon: StartInterviewLogo },
    { to: "/startNewClaim", label: "New Claim", Icon: ClaimDetailsLogo },
    // { to: "", label: "Claim Details", Icon: ClaimDetailsLogo },
    // { to: "", label: "Tools", Icon: ToolsLogo },
    // { to: "/documents", label: "Documents", Icon: DocumentsLogo },
  ];

  return (
    <aside
      ref={sidebarRef}
      className={`fixed left-0 top-0 z-50 h-screen w-64 bg-[#131313] text-white transition-transform duration-300 ease-linear ${
        sidebarOpen ? "translate-x-0" : "-translate-x-full"
      } lg:translate-x-0 lg:static lg:w-[16.3%]`}
    >
      <SidebarHeader />
      <SidebarContent
        navItems={navItems}
        location={location}
        hoveredItem={hoveredItem}
        setHoveredItem={setHoveredItem}
      />
    </aside>
  );
};

export default Sidebar;

export const SidebarHeader = () => (
  <div className="flex items-center justify-between h-[74px] gap-2 px-6 py-3.5 lg:py-3.5">
    <NavLink to="" className="w-full flex justify-center items-center">
      <img
        src={logo}
        alt="Logo"
        className={`w-[230px] relative top-[10px] lg:align-middle mx-auto h-[70px]`}
      />
    </NavLink>
  </div>
);

interface SidebarContentProps {
  navItems: { to: string; label: string; Icon: any }[];
  hoveredItem: string | null;
  setHoveredItem: (label: string | null) => void;
  location:any;
}

const SidebarContent = ({
  navItems,
  hoveredItem,
  setHoveredItem,
  location,
}: SidebarContentProps) => (
  <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear h-full">
    <nav className="mt-0 py-0 px-4 lg:mt-5 lg:px-6">
      <ul className="mb-6 flex flex-col gap-1.5">
        {navItems.map((item, index) => (
          <SidebarNavItem
            key={index}
            item={item}
            location={location}
            hoveredItem={hoveredItem}
            setHoveredItem={setHoveredItem}
          />
        ))}
      </ul>
    </nav>
  </div>
);

interface SidebarNavItemProps {
  item: { to: string; label: string; Icon: any };
  hoveredItem: string | null;
  setHoveredItem: (label: string | null) => void;
  location:any
}

const SidebarNavItem = ({
  item,
  hoveredItem,
  setHoveredItem,
  location
}: SidebarNavItemProps) => (
  <li>
    <NavLink
      to={item.to}
      className={`group relative flex items-center gap-2.5 whitespace-nowrap rounded-sm py-2 px-4 font-medium text-body dark1 duration-300 ease-in-out hover:border-[#1F7FBB] hover:border-b-2 hover:text-white  dark:hover:bg-meta-4 ${location?.pathname === item.to ? 'border-[#1F7FBB] border-b-2 text-white rounded-lg bg-meta-4':''}`}
      onMouseEnter={() => setHoveredItem(item.label)}
      onMouseLeave={() => setHoveredItem(null)}

    >
      <div
        className={`flex justify-center`}
      >
        <item.Icon active={hoveredItem === item.label || location?.pathname === item.to} />
      </div>
      {item.label}
    </NavLink>
  </li>
);

const useSidebarEffects = (
  sidebarOpen: boolean,
  setSidebarOpen: (arg: boolean) => void,
  sidebarRef: React.RefObject<any>,
  triggerRef: React.RefObject<any>
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target)
      ) {
        setSidebarOpen(false);
      }
    };

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && sidebarOpen) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [sidebarOpen, setSidebarOpen, sidebarRef, triggerRef]);
};

const useSidebarExpandedEffect = (sidebarExpanded: boolean) => {
  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.body.classList.add("sidebar-expanded");
    } else {
      document.body.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);
};


