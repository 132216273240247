import axios from "axios";
import BASE_URL from "./ApiUrl";

const axiosInstanceFileUpload = axios.create({
  baseURL:BASE_URL.defaultUrl,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});


export default axiosInstanceFileUpload;
