/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Interview_Tabs } from "../../utils/constant";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import {
  clearSelection,
  updateDateTime,
  updateTimeZone,
  updateId,
  updateDate,
  updateTime
} from "../../store/slices/botSlice";
import { useAppSelector } from "src/store/hooks";
import CalendarWithEvents from "./CalendarWithEvents";
import TalkWithBotForm from "./TalkWithBotForm";
import SuccessPath from "src/Features/successPath";
import { getScheduleList, deleteSchedule } from "src/api/interview";
import { toast } from "react-toastify";
import { resetDeleteSchedulingValue } from "src/store/slices/interviewSlice";
import talkbotimg from "../../assets/talk-bot-img.png";
import { Radio } from "@material-tailwind/react";
// import { Button } from "@material-tailwind/react";
import Button from "src/components/atoms/button";
import robot from "../../assets/images/calleli.png";
interface props {
  type: string;
  disableButton: boolean;
  siteData: any;
  setActiveTab: () => void;
  callEditScheduleApi: ({ item, data, moduleType }: InterViewProps) => void;
  onClickActionButton: ({
    data,
    actionType,
    moduleType,
  }: InterViewProps) => void;
}

const TalkWithBot = ({
  type,
  disableButton,
  siteData,
  callEditScheduleApi,
  onClickActionButton,
  setActiveTab,
}: props) => {
  const formValue = useAppSelector((state: RootState) => state.talkBot);
  const {
    getScheduleListData,
    deleteScheduleError,
    deleteScheduleRes,
    editScheduleError,
    editScheduleRes,
  } = useAppSelector((state: RootState) => state.interview);
  const dispatch = useDispatch();
  const dispatchForScheduleList = useDispatch<AppDispatch>();
  const [successMessage, setSuccesMessage] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [timeZones, setTimeZones] = useState([]);
  const [callType, setCallType] = useState(1);
  const [scheduleType, setScheduleType] = useState<number>(1);
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const handleDateTimeChange = (value: string) => {
    dispatch(updateDateTime(value));
  };

  const handleTimeZoneChange = (value: string) => {
    handleDateTimeChange("");
    dispatch(updateTimeZone(value));
  };

  const handleTimeChange = (value: string) => {
    dispatch(updateTime(value));
  };
  const handleDateChange = (value: string) => {
    dispatch(updateDate(value));
  };

  const handleItemIdChange = (data?: any, item?: any) => {
    setSelectedItem(item);
    dispatch(updateId(data));
  };

  const handleClearSelection = () => {
    dispatch(clearSelection());
    onClickActionButton({
      data: null,
      actionType: "RESET",
      moduleType: "TALK_WITH_BOAT",
    });
  };

  const callScheduleList = () => {
    const { email, claimNumber } = siteData;
    const payload = {
      insuredEmail: email,
      claimsId: claimNumber,
    };
    dispatchForScheduleList(getScheduleList(payload));
  };

  useEffect(() => {
    fetch("/timezones.json")
      .then((response) => response.json())
      .then((data) => setTimeZones(data))
      .catch((error) => console.error("Error loading timezones:", error));
  }, []);

  useEffect(() => {
    if (siteData?.email && siteData?.claimId) {
      if (type !== Interview_Tabs.TALK_WITH_BOT) {
        callScheduleList();
      }
    }
  }, [siteData]);
  const handleSubmit = () => {
    if (formValue.id) {
      callEditScheduleApi({
        item: selectedItem,
        data: {
          dateTime: formValue.dateTime,
          timeZone: formValue.timeZone,
          id: formValue.id,
        },
        moduleType:
          type === Interview_Tabs.ACCOUNTANT_CALL
            ? "TALK_WITH_ACCOUNTANT"
            : "TALK_WITH_BOAT",
      });
    } else {
      onClickActionButton({
        data: { dateTime: formValue.dateTime, timeZone: formValue.timeZone },
        actionType: "SUBMIT",
        moduleType:
          type === Interview_Tabs.ACCOUNTANT_CALL
            ? "TALK_WITH_ACCOUNTANT"
            : "TALK_WITH_BOAT",
      });
    }
    // setSuccesMessage(true);
  };
  const callDeleteSchedule = (id: any) => {
    const payload = {
      interviewId: id,
      claimsId: siteData?.claimNumber,
    };
    dispatchForScheduleList(deleteSchedule(payload));
  };
  const callResetValue = () => {
    dispatch(resetDeleteSchedulingValue());
  };

  useEffect(() => {
    if (deleteScheduleRes?.statusCode === 200) {
      toast.success(deleteScheduleRes?.message);
      callScheduleList();
      callResetValue();
    }
  }, [deleteScheduleRes]);
  useEffect(() => {
    if (editScheduleRes?.statusCode === 200) {
      toast.success(editScheduleRes?.message);
      callScheduleList();
      callResetValue();
      handleClearSelection();
    }
  }, [editScheduleRes]);
  useEffect(() => {
    if (deleteScheduleError) {
      toast.error(deleteScheduleRes?.message);
      callResetValue();
    }
    if (editScheduleError) {
      toast.error(editScheduleRes?.message);
      callResetValue();
    }
  }, [deleteScheduleError, editScheduleError]);
  return !successMessage ? (
    <div className=" h-auto rounded-sm m-auto w-[100%] mt-4 py-2 flex justify-center bg-[#F8F9FA]">
      <div className="flex flex-col w-[70%]">
        {/* <button disabled className="hover:cursor-default text-left"> */}
        {type === Interview_Tabs.TALK_WITH_BOT && (
          <>
            <h2 className="text-[#009BDB] whitespace-nowrap font-poppins  text-base font-normal h-auto mb-2 mt-5">
              When would you like to schedule the call?
            </h2>

            <div className="flex gap-10">
              <Radio
              disabled={disableButton}
                name="schedule"
                label="Schedule a Call With Eli "
                defaultChecked
                onClick={() => setScheduleType(1)}
              />
              <Radio
                disabled={disableButton}
                name="schedule"
                label="Speak with Eli Now "
                onClick={() => setScheduleType(2)}
              />
            </div>
            {scheduleType === 2 && (
              <div className="h-1 w-[550px] bg-[#D9D9D9] rounded-full mt-2"></div>
            )}
            {scheduleType === 2 && (
              <div className="flex">
                <div className="w-1/2">
                  <h2 className="text-[#009BDB] whitespace-nowrap font-poppins  text-base font-normal h-auto mb-2 mt-2">
                    How would you like to initiate the call?
                  </h2>
                  <div className="flex gap-10">
                    <Radio
                      name="callType"
                      label="Call Eli via Web Call"
                      checked={callType === 1}
                      defaultChecked
                      onClick={() => setCallType(1)}
                    />
                    <Radio
                      name="callType"
                      label="Call Eli via Mobile"
                      checked={callType === 2}
                      onClick={() => setCallType(2)}
                    />
                  </div>
                  {callType === 1 && (
                    <>
                    <p className="text-[#f50404] pl-4 text-sm font-semibold pt-3">
                    Note: <span className="!text-[#000]">Please use your microphone for better quality and calling experience.</span>
                  </p>
                    <div className="w-40 mt-8 mb-4">
                      <Button style={{ borderRadius: "5px" }}>
                      <a href="tel:+1 (888) 470-1067">Initiate Call</a>
                      </Button>
                    </div>
                    </>
                  )}
                    {disableButton && type === Interview_Tabs.TALK_WITH_BOT && (
          <p className="text-[#f50404] pl-4 text-sm font-semibold pt-3">
            Note: <span className="">Your interview is already scheduled.</span>
          </p>
        )}
        {type === Interview_Tabs.TALK_WITH_BOT &&
          scheduleType === 2 &&
          callType === 2 && (
            <h2 className="text-[#000]-500 font-poppins  text-base font-medium h-auto mt-6">
             To speak with Eli now please dial
              <span className="text-[#1F7FBB] font-semibold">
                {" "}
                +1 (888) 470-1067
              </span>
              .
            </h2>
          )}
                </div>
                <div className="w-1/2">
                 <img src ={robot} className="relative top-[-32%] left-[30%]"/>
                </div>
              </div>
            )}
          </>
        )}
        {/* </button> */}

        {/* <div className="grid grid-cols-2">
          <div>ss</div>
          <img src={talkbotimg}  className="flex"/>
        </div> */}
      

        {scheduleType === 1 && (
          <>
            <TalkWithBotForm
              formValue={formValue}
              disableButton={disableButton}
              timeZones={timeZones}
              onDateTimeChange={handleDateTimeChange}
              onTimeZoneChange={handleTimeZoneChange}
              onTimeChange={handleTimeChange}
              onDateChange={handleDateChange}
              onSubmit={handleSubmit}
              onClear={handleClearSelection}
            />
            {disableButton && (
              <p className="text-[#f50404] pl-5 font-semibold text-sm">
                Note:{" "}
                <span className="">Your interview is already scheduled.</span>
              </p>
            )}
            {getScheduleListData?.length > 0 && (
              <CalendarWithEvents
                selectedDate={selectedDate}
                events={getScheduleListData}
                handleItemIdChange={handleItemIdChange}
                onDateChange={setSelectedDate}
                callDeleteSchedule={callDeleteSchedule}
                setActiveTab={setActiveTab}
              />
            )}
          </>
        )}
      </div>
    </div>
  ) : (
    <div>
      <SuccessPath
        message="Thank you
Your call has been scheduled successfully!"
        setShow={setSuccesMessage}
      />
    </div>
  );
};
const getFormattedDate = (date: Date) => {
  return date.toISOString().split("T")[0]; // Converts to YYYY-MM-DD format
};

export default TalkWithBot;
