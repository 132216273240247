/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import chatbot from "../../../assets/images/chatbot.png";
import talkBot from "../../../assets/images/talkbot.png";
import talkAccountant from "../../../assets/images/talkAccountant.png";
import questionIcon from "../../../assets/images/questionnaire_icon.png";
import { useCallback } from "react";
import { Interview_Tabs } from "../../../utils/constant";
interface Props {
    setActiveTab: (e?: string) => void
    activeTab:any
  }
const ScheduleTabs =({activeTab,setActiveTab}: Props) => {
    const handleTab = useCallback((step: string) => {
      setActiveTab(step);
    }, []);
  
    const tabs= [
        {
            title:"Chat with Eli",
            icon:chatbot,
            description:"Eli will ask questions about your claim, and you can provide Eli with the details of your loss.",
            buttonText:"Start ChatBot",
            selection:Interview_Tabs.CHAT_WITH_BOT
         
        },
        {
            title:"Call Eli",
            icon:talkBot,
            description:"You can reach out to Eli and share the details of your loss through either a web call or a phone call.",
            buttonText:"Call Now",
            selection:Interview_Tabs.TALK_WITH_BOT
        },
        {
            title:"Talk To our Accountant",
            icon:talkAccountant,
            description:"You will receive a call from our accounting team at your preferred date & time to provide the information regarding your loss.",
            buttonText:"Schedule Interview",
            selection:Interview_Tabs.ACCOUNTANT_CALL
        },
        {
            title:"Claim Intake",
            icon:questionIcon,
            description:"You can complete a questionnaire to give further details about your loss.",
            buttonText:"Fill out the form",
            selection:Interview_Tabs.QUESTIONNAIRE
        }
    ]
    
    return (
        <div className="container flex flex-col w-full mt-2">
            <span className="text-lg text-[#000] font-semibold">Please choose your preferred interview mode:</span>
            <div className="grid grid-cols-2 sm:grid-cols-1  md:grid-cols-2 lg:grid-cols-2 gap-4 mt-2">
             {tabs.map((item,key)=>
                <div key={key} className="z-[999999] rounded overflow-hidden shadow-lg border-[3px] border-[#0774D9] rounded-xl h-[200px] p-2">
                    <div className="flex">
                    <img src={item.icon} width={item.title=="Call Eli"?"40px":"60px"}/>
                    <div className="block">
                    <p className="font-semibold text-base  g ml-2 whitespace-nowrap">{item.title}</p>
                    <div className="line rounded-xl"></div>
                    </div>
                  </div>
                  <div className="text-base text-justify mt-2 leading-5 mt-2 h-14">
                    {item.description}
                  </div>
                  <div className="justify-items-end pt-4 pb-2">
               

                  <button className="z-[999999] inline-block text-white rounded-full bg-[#0774D9] flex justify-center gap-5 items-center cursor-pointer font-[500] text-[14px] leading-[27px] pl-24 pr-24 h-[40px] whitespace-nowrap w-[20%] text-center"  
                    onClick={()=>handleTab(item.selection)}><span>{item.buttonText}
                  </span></button>
               
                  </div>
               </div>)}
            </div>
          <div className="text-base font-semibold mt-4"><span className=" text-[#FF1212]">Note:-&nbsp;</span>
          Eli is a friendly and efficient chatbot designed to help you report information about your business interruption and extra expense losses. Through a series of guided questions, Eli ensures that all necessary details are collected accurately, making it easier for users to share the information needed for accurate and swift claim resolution.
            </div>
        </div>
    )
    
}
export default ScheduleTabs