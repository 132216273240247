import { LoadScript } from "@react-google-maps/api";
import DefinePath from "../Features/Router";

function App() {
  return (
    <LoadScript googleMapsApiKey="AIzaSyAI10DY-Vuc-BYb4McbIm2J4wV-ywoDDeE" libraries={["places"]}>
      <DefinePath />
    </LoadScript>
  );
}

export default App;
