import React from "react";
import { NavLink, Outlet } from "react-router-dom";

interface PropsState{
  hideHeader?:boolean
}

export const SidebarHeader = () => (
    <div className="flex items-center justify-between h-[74px] gap-2 px-6 py-3.5 lg:py-3.5 bg-white">
      <NavLink to="" className="w-full flex justify-center items-center">
        <img
          src="/images/final_logo_1.png"
          alt="Logo"
          className={`w-[200px] relative top-[10px] lg:align-middle mx-auto h-[40px]`}
        />
      </NavLink>
    </div>
  );
  
const InterviewSchedlueLayout: React.FC<PropsState> = ({hideHeader}) => {

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark bg-white font-verdana h-[100vh]">
      <div className=" h-screen overflow-hidden">
      <header className={`sticky top-0 z-50 flex w-full h-[8%]"`}>
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11 h-[67px]">
       <SidebarHeader/>
      </div>
    </header>
      {<div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden h-[110vh]">
          <main className="flex-1">
            <div className={`mx-auto max-w-screen bg-white h-[90vh] overflow-y-auto ${hideHeader?"":"p-2 md:p-4 2xl:p-6"}`}>
              <Outlet />
            </div>

          </main>
        </div>}
        
      </div>
    </div>
  );
};

export default InterviewSchedlueLayout;
