import React, { useEffect } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
  Checkbox,
} from "@material-tailwind/react";
// import transcriptLogo from "../../../../images/transcript.png";
import transcriptLogo from "../../../assets/images/transcript.png";
import statementLogo from "../../../assets/images/statement.png";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getClaimDocumets } from "src/api/documentApi";
import { RootState } from "src/store";
import moment from "moment";
import axiosInstance from "src/api/axiosInstance";
import { toast } from "react-toastify";
import Loader from "src/components/loader";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "src/components/CustomButton";

const tabData = [
  {
    label: "Interview Transcript",
    value: "interview_transcript",
    logo: transcriptLogo,
  },
  {
    label: "Statement & Invoices",
    value: "statement_invoices",
    logo: statementLogo,
  },
];

const Documents: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const claim = location.state?.claim;
  const [activeTab, setActiveTab] = React.useState("interview_transcript");
  const { documents, loading } = useAppSelector(
    (state: RootState) => state.documents
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (claim?.id) {
      const payload: GetFileProps = { claimId: claim?.id };
      dispatch(getClaimDocumets(payload));
    }
  }, [claim, dispatch]);

  if (!claim) {
    return null;
  }

  const renderTableHeaders = (headers: string[]) => (
    <thead className="bg-[#cfdce4]">
      <tr>
        {headers.map((header, idx) => (
          <th
            key={idx}
            className="px-3 py-3 font-semibold text-black text-sm  tracking-wider border border-black"
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );

  const getModeOfInterview = (item: DocumentProps) => {
    if (item.fileName.startsWith("c_")) {
      return "Chatbot";
    } else {
      return "Questionnaire";
    }
  };

  const downloadServerFile = (item: DocumentProps) => {
    const payload: GetFileProps = { claimId: claim?.id, fileId: item.id };
    const url = `${axiosInstance.getUri()}/claim/getFile/${payload.fileId}/${payload.claimId}`;
    window.location.href = url;
    setTimeout(() => {
      toast.success("Downloaded file successfully!");
    }, 1000);
  };
  const redirectToOcrDocument =()=>{
    navigate("/claim-details/claim-documents/ocr-documents", { state: { claim } })

  }


  const renderInterviewTranscriptBody = () => {
    const transcriptDoc = documents.filter(
      (item: DocumentProps) => item.type === "Transcript"
    );
    return (
      <tbody className="bg-white">
        {transcriptDoc.length === 0 && (
          <tr className="px-3 py-3 hover:bg-gray-100">
            <td aria-colspan={4} className="px-3 py-3 text-sm text-black ">
              No Data Found!
            </td>
          </tr>
        )}
        {transcriptDoc.map((item, index) => (
          <tr key={index} className="hover:bg-gray-100">
            <td className="px-3 py-3 text-sm text-black border border-black">
              <Checkbox
                id={item.id}
                value={item.id}
                color={"black"}
                className={`w-[1.25rem] mt-0`}
              />
            </td>
            <td className="px-3 py-3 text-sm text-black border border-black">
              {moment(item?.date_time_updated).format("DD/MM/YYYY")}
            </td>
            <td className="px-3 py-3 text-sm text-black border border-black">
              {getModeOfInterview(item) || "-"}
            </td>
            <td className="px-3 py-3 text-sm text-[#1F7FBB] cursor-pointer underline border border-black  ">
              <p
                className="cursor-pointer overflow-hidden truncate w-48 m-auto"
                onClick={() => downloadServerFile(item)}
              >
                <Tooltip
                  content={item.fileName}
                  animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                  }}
                  placement="top"
                >
                  {item?.fileName || "-"}
                </Tooltip>
              </p>
            </td>
            <td className={`px-3 py-3 text-sm  border border-black  `}>
              <p
                className={`cursor-pointer overflow-hidden truncate w-48 m-auto`}
                // onClick={()=>redirectToOcrDocument()}
              >
                Not Available
              </p>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const renderStatementInvoicesBody = () => {
    const interViewFileList = documents.filter(
      (item: DocumentProps) => item.type === "File" && !item.isArchived
    );
    return (
      <tbody className="bg-white">
        {interViewFileList.length === 0 && (
          <tr className="px-3 py-3 hover:bg-gray-100">
            <td aria-colspan={2} className="px-3 py-3 text-sm text-black ">
              No Data Found!
            </td>
          </tr>
        )}
        {interViewFileList.map((item, index) => (
          <tr key={index} className="hover:bg-gray-100">
            <td className="px-3 py-3 text-sm text-black border border-black">
              <Checkbox
                id={item.id}
                value={item.id}
                color={"black"}
                className={`w-[1.25rem] mt-0`}
              />
            </td>
            <td className="px-3 py-3 text-sm text-black border border-black">
              {moment(item?.date_time_updated).format("DD/MM/YYYY")}
            </td>
            <td className="px-3 py-3 text-sm text-[#1F7FBB] cursor-pointer underline border border-black  justify-center">
              <p
                className="cursor-pointer overflow-hidden truncate w-48 text-center m-auto"
                onClick={() => downloadServerFile(item)}
              >
                <Tooltip
                  content={item.fileName}
                  animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                  }}
                  placement="top"
                >
                  {item.fileName}
                </Tooltip>
              </p>
            </td>
            <td className="px-3 py-3 text-sm text-[#1F7FBB] cursor-pointer underline border border-black flex justify-center">
              <p className="cursor-pointer overflow-hidden truncate w-48 text-center">
                <Tooltip content={""}
                   animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                  }}
                  placement="top">
               

                  </Tooltip>
                Not Available
              </p>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const renderBackButton = () => (
    <div className="flex gap-2 items-center cursor-pointer text-lg sm:text-xl">
      <span
        className="font-semibold"
        onClick={() => navigate("/claim-details", { state: { claim } })}
      >
      <KeyboardBackspaceIcon fontSize="large" />
        Back
      </span>
    </div>
  );

  const renderDocumentHeader = () => (
    <div className="flex mt-3 justify-between items-center cursor-pointer text-2xl sm:text-3xl bg-[#cfdce4] py-1">
      <div className="flex items-center py-2 pl-5">
        <span className="font-semibold text-lg">Documents</span>
        <img
          src={"/images/documents.png"}
          alt="Documents"
          className="w-6 h-6 sm:w-12 sm:h-6"
        />
      </div>
    </div>
  );

  return (
    <div className="relative p-4 sm:p-1">
      {renderBackButton()}
      {renderDocumentHeader()}
      {loading && <Loader />}
      <div className="pt-8 sm:pl-10">
        <Tabs value={activeTab}>
          <TabsHeader
            className="rounded-none border-b border-blue-gray-50 bg-transparent p-0 w-full md:w-7/12"
            indicatorProps={{
              className:
                "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
            }}
          >
            {tabData.map(({ label, value, logo }) => (
              <Tab
                key={value}
                value={value}
                onClick={() => setActiveTab(value)}
                className={
                  activeTab === value
                    ? "text-gray-900 font-bold"
                    : "font-medium"
                }
              >
                <div className="flex items-center text-sm md:text-base">
                  <img
                    src={logo}
                    alt={`${label} Logo`}
                    className="w-6 h-5 mr-2 md:w-8 md:h-6"
                  />
                  {label}
                </div>
              </Tab>
            ))}
          </TabsHeader>

          <TabsBody>
            <TabPanel key="interview_transcript" value="interview_transcript">
              <div className="md:pr-40 overflow-x-auto">
                {documents.filter(
                  (item: DocumentProps) => item.type === "Transcript"
                ).length > 0 && (
                  <div className="flex lg:flex-row md:flex-row sm:flex-row  gap-2 py-4 px-4 content-center justify-end ">
                    <CustomButton
                      className="w-full sm:w-auto bg-[#1f7fbb] rounded-lg pl-7 pr-7 pt-4 pb-4 !text-white border-none font-semibold transition-transform transform active:scale-95 shadow-lg hover:shadow-xl"
                      label="Generate OCR link "
                      onClick={() => console.log("Ddddd")}
                    />
                  </div>
                )}
                <table className="min-w-full border border-gray-300 text-center">
                  {renderTableHeaders([
                    "Select",
                    "Interview Date",
                    "Mode of Interview",
                    "Transcript Name",
                    "OCR Results Links",
                  ])}
                  {renderInterviewTranscriptBody()}
                </table>
              </div>
            </TabPanel>

            <TabPanel key="statement_invoices" value="statement_invoices">
              <div className="md:pr-40 overflow-x-auto">
              {documents.filter(
                  (item: DocumentProps) => item.type === "File" && !item.isArchived
                ).length > 0 && ( <div className="flex lg:flex-row md:flex-row sm:flex-row  gap-2 py-4 px-4 content-center justify-end ">
                  <CustomButton
                    className="w-full !text-sm sm:w-auto bg-[#1f7fbb] rounded-lg pl-7 pr-7 pt-4 pb-4 !text-white border-none font-semibold transition-transform transform active:scale-95 shadow-lg hover:shadow-xl"
                    label="Generate OCR link "
                    onClick={() => console.log("Ddddd")}
                  />
                </div>)}
                <table className="min-w-full border border-gray-300 text-center">
                  {renderTableHeaders([
                    "Select",
                    "Submission Date",
                    "Document Name",
                    "OCR Results Links",
                  ])}
                  {renderStatementInvoicesBody()}
                </table>
              </div>
            </TabPanel>
          </TabsBody>
        </Tabs>
      </div>
    </div>
  );
};

export default Documents;
