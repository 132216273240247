import PolicyGrid from "../modules/Documents/Policy Docs/PolicyGrid";
import PicturesGrid from "../modules/Documents/Pictures Docs/PicturesGrid";
import PayrollGrid from "../modules/Documents/Payroll Docs/PayrollGrid";
import InvoicesGrid from "../modules/Documents/Invoices Docs/InvoicesGrid";
import FinancialStatementGrid from "../modules/Documents/Financial Statements Docs/FinancialStatementGrid";
import {
  coverage,
  DemographicData,
  FinancialStatement,
  LossInformation,
} from "./mockData";
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface progresBarTabsProps {
  step: number;
  name: string;
  percentage: number;
  data?: any;
}
export enum Interview_Tabs {
  TALK_WITH_BOT = "Call Eli",
  CHAT_WITH_BOT = "Chat with Eli",
  ACCOUNTANT_CALL = "Talk to Our Accountant",
  QUESTIONNAIRE = "Claim intake",
}
export const timeZones = [
  { name: "Pacific Standard Time", value: "Pacific Standard Time" },
  { name: "Eastern Standard Time", value: "Eastern Standard Time" },
  { name: "Central Standard Time", value: "Central Standard Time" },
  { name: "Mountain Standard Time", value: "Mountain Standard Time" },
];

export const statusBorderColor:any ={
  "Planned":"131313",
  "Ready To Start":"14532D",
  "In Progress":"Ffff00",
  "Waiting":"FF2C2C",
  "Completed":"D97706",
  "Cancelled":"B60F0F"
}
export const statusBgColor :any ={
  "Planned":"rgb(251 191 36 0.1)",
  "Ready To Start":"rgb(217 119 6 0.1)",
  "In Progress":"rgb(132 204 22 0.1)",
  "Waiting":"rgb(21 94 117 0.1)",
  "Completed":"rgb(20 83 45 0.1)",
  "Cancelled":"rgb(136 19 55 0.1)"
}

export const steppertabs: Array<progresBarTabsProps> = [
  {
    step: 1,
    name: "Business Information",
    percentage: 0,
    data: DemographicData,
  },
  { step: 2, name: "Loss Information", percentage: 20, data: LossInformation },
  { step: 3, name: "Coverage", percentage: 40, data: coverage },
  // {
  //   step: 4,
  //   name: "Financial statements",
  //   percentage: 60,
  //   data: FinancialStatement,
  // },
  { step: 5, name: "Repair Timeline", percentage: 80 },
  { step: 6, name: "Review", percentage: 100 },
];

// for Dashboard Claims
export const STATUS_OPTIONS = [
  { name: "Planned", value: "Planned" },
  {name:"Ready To Start",value:
    "Ready To Start"
  },
  {
    name: "In Progress - Kick-off / Setup",
    value: "In Progress - Kick-off / Setup",
  },
  {
    name: "In Progress - Prep",
    value: "In Progress - Prep",
  },
  {
    name: "In Progress - Process",
    value: "In Progress - Process",
  },
  {
    name: "In Progress - Review",
    value: "In Progress - Review",
  },
  {
    name: "In Progress - Follow-up",
    value: "In Progress - Follow-up",
  },
  {
    name: "Waiting - Waiting for info",
    value: "Waiting - Waiting for info",
  },
  {
    name: "Waiting - Interview Scheduled",
    value: "Waiting - Interview Scheduled",
  },
  {
    name: "Waiting - Interview Completed",
    value: "Waiting - Interview Completed",
  },
  {
    name: "Waiting - Interview To Be Completed",
    value: "Waiting - Interview To Be Completed",
  },
  // {
  //   name: "Waiting - Waiting for client",
  //   value: "Waiting - Waiting for client",
  // },
  {
    name: "Waiting - Waiting for confirmation",
    value: "Waiting - Waiting for confirmation",
  },
  {
    name: "Completed - Cancelled",
    value: "Completed - Cancelled",
  },
];

export const documentsTabs = [
  {
    label: "Upload Policy",
    value: "upload_policy",
    component: PolicyGrid,
  },
  {
    label: "Upload Financial Statements",
    value: "upload_financial_statements",
    component: FinancialStatementGrid,
  },
  {
    label: "Upload Pictures",
    value: "upload_pictures",
    component: PicturesGrid,
  },
  {
    label: "Upload Payroll Docs",
    value: "upload_payroll_docs",
    component: PayrollGrid,
  },
  {
    label: "Upload Invoices",
    value: "upload_invoices",
    component: InvoicesGrid,
  },
];

export const ASSIGNEE_LIST = [
  // { name: "Joseph Scarlato", value: "joseph.scarlato@ampcusforensics.com" },
  { name: "Grant Mizel", value: "grant.mizel@ampcusforensics.com" },
  { name: "Priya Krishnamurthy", value: "priya.krishnamurthy@ampcusforensics.com" },
  // { name: "Vedant Jadhav", value: "vedant.jadhav@ampcustech.com" },
  // { name: "Loknath Prajapati", value: "loknath.prajapati@ampcustech.com" },
];

export const OCR_FILE_TYPES=[
  {
  label:"1125-A",
  value:"1125-A"
},
{
  label:"1065",
  value:"1065"
},
{
  label:"1040-C",
  value:"1040-C"
},
{
  label:"1120",
  value:"1120"
},
{
  label:"1120-S",
  value:"1120-S"
},
{
  label:"Invoice",
  value:"Invoice"
},

]

export const LANGUAGE:any=
[
  {
    value:"en",
    name:"English",
    icon:`<a href="https://www.flaticon.com/free-icons/united-kingdom" title="united kingdom icons">United kingdom icons created by Freepik - Flaticon</a>`
  },
  {
    value:"es",
    name:"Spanish",
    icon:`<a href="https://www.flaticon.com/free-icons/united-kingdom" title="united kingdom icons">United kingdom icons created by Freepik - Flaticon</a>`
  },
  {
    value:"pt",
    name:"Portuguese",
    icon:`<a href="https://www.flaticon.com/free-icons/united-kingdom" title="united kingdom icons">United kingdom icons created by Freepik - Flaticon</a>`
  }
]