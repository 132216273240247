import { rest } from "lodash";

export const formatAMPM = (date: Date) => {
  let hours: any = date.getHours();
  let minutes: any = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};

export const getRandomColor = () => {
  return "#" + Math.random().toString(16).slice(2, 8);
};

export const allowValidMobileNumber=(value:any)=>{
const reg=/^[0-9\b]+$/
if(reg.test(value)||value===""){

}

}

export const emailValidation =(value:any)=>{
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/; 
  if(emailRegex.test(value)||value===""){
   return true
  }
  return false
}

export const onlyAlphabet=(value:any)=>{
  const alphabetRegex = /^[a-zA-Z]*$/;
  if(alphabetRegex.test(value)||value===""){
    return true
   }
   return false
}
export const chunkArray = (data:any, itemsPerRow:any) => {
  const rows = [];
  for (let i = 0; i < data.length; i += itemsPerRow) {
    rows.push(data.slice(i, i + itemsPerRow));
  }
  return rows;
};