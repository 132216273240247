import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";

interface FormState {
  id?:any;
  dateTime: string;
  timeZone: string;
  date?:any
  time?:string
}

const initialState: FormState = {
  id:"",
  dateTime: "",
  timeZone: "",
};

export const botSlice = createSlice({
  name: "talkBot",
  initialState,
  reducers: {
    updateDateTime: (state, action: PayloadAction<string>) => {
      const newState = cloneDeep(state);
      newState.dateTime = action.payload;
      return newState;
    },
    updateTimeZone: (state, action: PayloadAction<string>) => {
      const newState = cloneDeep(state);
      newState.timeZone = action.payload;
      return newState;
    },
    updateTime: (state, action: PayloadAction<string>) => {
      const newState = cloneDeep(state);
      newState.time = action.payload;
      return newState;
    },
    updateDate: (state, action: PayloadAction<string>) => {
      const newState = cloneDeep(state);
      newState.date = action.payload;
      return newState;
    },
    updateId: (state, action: any) => {
      const newState = cloneDeep(state);
      newState.id = action?.payload?.id;
      newState.dateTime = action?.payload?.dateTime;
      newState.timeZone = action?.payload?.timeZone;
      return newState;
    },
    clearSelection: () => initialState,
  },
});

export const { updateDateTime, updateTimeZone, clearSelection,updateId,updateTime,updateDate } =
  botSlice.actions;

export default botSlice.reducer;
