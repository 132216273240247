/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { RootState } from "src/store";
import { getClaimDetailsById } from "src/api/claimApi";
import CustomButton from "src/components/CustomButton";
import moment from "moment";
import FormInput from "src/components/FormInput";
import { DocumentCreator } from './doc-generator';
import { Packer } from 'docx';
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { uploadReport } from "src/api/documentApi";
import Loader from "src/components/loader";
import FormSelect from "src/components/FormSelect";
const InitateReporting: React.FC = () => {
  const [salutation,setSalutation] = useState("")
  const [incidentType,setIncidentType]= useState("")
  const navigate = useNavigate();
  const location = useLocation();
  const claim = location.state?.claim;
  const dispatch = useAppDispatch();
  const { claimDetails, loading } = useAppSelector(
    (state: RootState) => state.claim
  );
  const OPTIONS=[{name:"Mr.",value:"Mr."},{name:"Mrs.",value:"Mrs."},{name:"Ms.",value:"Ms."},{name:"Dr.",value:"Dr."}] 
  const { loading: loadingReport, error: errorReporting ,data } = useAppSelector(
    (state: RootState) => state.reporting
  );
  useEffect(() => {
    dispatch(getClaimDetailsById(claim.id));
  }, [claim]);
  const generateAndSave=async ()=>{
    const documentCreator = new DocumentCreator();
        const doc = await documentCreator.create([
          claimDetails,
          incidentType,
          salutation
        ]);
        Packer.toBlob(doc).then(blob => {
          saveAs(blob, `claim-${claim.claimNumber}.docx`);
          const file = new File([blob], `claim-${claim.claimNumber}.docx`, {
            type: blob.type,
            lastModified: new Date().getTime()
        });
        let payload={
          claimId:claim.id,
          file:file
        }
        dispatch(uploadReport(payload));
        });
  }

  useEffect(()=>{
   if(data?.statusCode === 200){
       toast.success("Document created successfully")
   }
   if(errorReporting){
    toast.error("Error in creating document")  
   }
  },[data,
    errorReporting
  ])
  const renderBackButton = () => (
    <div className="flex gap-2 items-center cursor-pointer text-lg sm:text-xl">
      <span className="font-semibold" onClick={() => navigate("/dashboard")}>
        <KeyboardBackspaceIcon fontSize="large" />
        Back
      </span>
    </div>
  );
  
  const onReset=()=>{
    setIncidentType("")
    setSalutation("")
  }

  const claimInfo = () => (
    <div className="flex h-auto mt-4 rounded-xl shadow-md bg-white border-2 w-full border-blue-500 justify-between items-center py-2">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
        <div className="whitespace-nowrap text-sm font-medium px-4 py-2">
          Claim Number : &nbsp;
          <span className="text-[#1F7FBB] font-semibold">
            {"#"}
            {claimDetails?.claimNumber}
          </span>
        </div>
        <div className=" whitespace-nowrap text-sm font-medium px-4 py-2">
          Insured Business Name : &nbsp;
          <span className="text-[#1F7FBB] font-semibold">
            {claimDetails?.insuredBusinessName}
          </span>
        </div>
      </div>
    </div>
  );
  const renderTableHeaders = (headers: string[]) => (
    <thead className="bg-[#cfdce4]">
      <tr>
        {headers.map((header, idx) => (
          <th
            key={idx}
            colSpan={2}
            className="px-3 py-3 font-semibold text-black text-sm  tracking-wider "
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
  const renderBody = () => {
    return (
      <tbody className="bg-white">
          <tr className="hover:bg-gray-100">
          <td className="px-3 py-3 font-semibold text-sm text-black ">
          Salutation
          </td>
          <td className="px-3 py-3 text-sm text-[#575555] ">
          <FormSelect  items={OPTIONS}               
          className="p-0 pl-[16px] text-sm text-blue-gray-700 w-full h-[34px] border-2 rounded-md border-[#CECECE] bg-white focus:shadow-lg focus:shadow-sky-100 focus:border-1 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10"
          selectedItem={salutation}  
          onChange={(value)=>setSalutation(value)}/>
               
          </td>
        </tr>
        <tr className="hover:bg-gray-100">
          <td className="px-3 py-3 font-semibold text-sm text-black ">
             First Name 
          </td>
          <td className="px-3 py-3 text-sm text-[#575555] ">
            {claimDetails?.clientDetails?.firstName}
          </td>
        </tr>
        <tr className="hover:bg-gray-100">
          <td className="px-3 py-3 font-semibold text-sm text-black ">
             Last Name 
          </td>
          <td className="px-3 py-3 text-sm text-[#575555] ">
            {claimDetails?.clientDetails?.lastName}
          </td>
        </tr>
        <tr className="hover:bg-gray-100">
          <td className="px-3 py-3 font-semibold text-sm text-black ">
             Company 
          </td>
          <td className="px-3 py-3 text-sm text-[#575555] ">
            {claimDetails?.clientDetails?.clientCompany}
          </td>
        </tr>
        <tr className="hover:bg-gray-100">
          <td className="px-3 py-3 font-semibold text-sm text-black ">
            Email Address
          </td>
          <td className="px-3 py-3 text-sm text-[#575555] ">
            {claimDetails?.clientDetails?.emailAddress}
          </td>
        </tr>
        <tr className="hover:bg-gray-100">
          <td className="px-3 py-3 font-semibold text-sm text-black ">
            Phone Number
          </td>
          <td className="px-3 py-3 text-sm text-[#575555] ">
            {claimDetails?.clientDetails?.phoneNumber}
          </td>
        </tr>
      </tbody>
    );
  };

  const renderClaimBody = () => {
    return (
      <tbody className="bg-white">
        <tr className="hover:bg-gray-100">
          <td className="px-3 py-3 font-semibold text-sm text-black ">
          Insured Name 
          </td>
          <td className="px-3 py-3 text-sm text-[#575555] ">
            {claimDetails?.insuredName}
          </td>
        </tr>
        <tr className="hover:bg-gray-100">
          <td className="px-3 py-3 font-semibold text-sm text-black ">
             Phone Number
          </td>
          <td className="px-3 py-3 text-sm text-[#575555] ">
            {claimDetails?.insuredPhoneNumber}
          </td>
        </tr>
  
        <tr className="hover:bg-gray-100">
          <td className="px-3 py-3 font-semibold text-sm text-black ">
            Email Address
          </td>
          <td className="px-3 py-3 text-sm text-[#575555] ">
            {claimDetails?.insuredEmail}
          </td>
        </tr>
        <tr className="hover:bg-gray-100">
          <td className="px-3 py-3 font-semibold text-sm text-black ">
          AFI File No.
          </td>
          <td className="px-3 py-3 text-sm text-[#575555] ">
            {claimDetails?.fileNumber}
          </td>
        </tr>
        <tr className="hover:bg-gray-100">
          <td className="px-3 py-3 font-semibold text-sm text-black ">
          Date of Incident
          </td>
          <td className="px-3 py-3 text-sm text-[#575555] ">
            {moment(claimDetails?.dateOfLoss).format("YYYY-MM-DD")}
          </td>
        </tr>
        <tr className="hover:bg-gray-100">
          <td className="px-3 py-3 font-semibold text-sm text-black ">
          Type of Incident
          </td>
          <td className="px-3 py-3 text-sm text-[#575555] ">
          <FormInput required  className={"!w-[220px]"} value={incidentType} type="text" placeholder=""  onChange={(e)=>setIncidentType(e.target.value)}/>
               
          </td>
        </tr>
      </tbody>
    );
  };
  return (
    <div className="relative p-4 sm:p-1">
      {(loading || loadingReport )
         && (
        <Loader />
      ) }
      {renderBackButton()}
      {claimInfo()}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
     
      <table className="min-w-full border border-gray-300  mt-4">
        {renderTableHeaders(["Claim Information"])}
        {renderClaimBody()}
      </table>
      <table className="min-w-full border border-gray-300  mt-4">
        {renderTableHeaders(["Client Information"])}
        {renderBody()}
      </table>
      </div>
      {
        <div className="flex flex-col sm:flex-row gap-3 mt-4 justify-center">
          <CustomButton
           onClick={generateAndSave}
            className="w-full sm:w-auto bg-[#1f7fbb] rounded-lg !text-white border-none font-semibold transition-transform transform active:scale-95 shadow-lg hover:shadow-xl"
            label="Initiate Reporting"
          />
            <CustomButton
            className="w-full sm:w-auto rounded-lg bg-black  !text-white border-none font-semibold hover:!text-white transition-transform transform active:scale-95 shadow-lg hover:shadow-xl"
            label="Reset"
            onClick={onReset}
          />
        </div>
      }
    </div>
  );
};
export default InitateReporting;
