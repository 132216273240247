/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import FormInput from "../../components/FormInput";
import ComboBox from "../../components/ClientSearchComponents";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { setClientInfo } from "src/store/slices/claimSlice";
import { ClaimData } from "./ClaimData";
import { useCallback, useEffect, useState } from "react";
import { getClientList } from "../../api/clientApi";
import { emailValidation, onlyAlphabet } from "src/utils";

interface InputProps {
  label: string;
  key: keyof ClientInfo;
  required: boolean;
  disabled: boolean;
  isVisible?: boolean;
  isMatch?:boolean
}

type ClientInfo = ClaimData["newClientDetails"];
const ClientInformation: React.FC = () => {
  const dispatchForClient = useDispatch<AppDispatch>();
  const dispatch = useDispatch();
  const [testClientName, setTestClientName] = useState<string>("");
  const clientInfo = useSelector((state: RootState) => state.claim.clientInfo);
  const { clients } = useSelector((state: RootState) => state.client);

  const [isCreateNewContact, setIsCreateNewContact] = useState(false);

  let clientInputs: InputProps[] = [
    {
      label: "Client ID",
      key: "clientId",
      required: false,
      disabled: true,
      isVisible: !isCreateNewContact,
    },
    {
      label: "Client First Name",
      key: "firstName",
      required: true,
      disabled: false,
      isVisible: isCreateNewContact,
    },
    {
      label: "Client Last Name",
      key: "lastName",
      required: true,
      disabled: false,
      isVisible: isCreateNewContact,
    },
    {
      label: "Client Job Title",
      key: "clientTitle",
      required: false,
      disabled: false || !isCreateNewContact,
      isVisible: true,
    },
    {
      label: "Client Company",
      key: "clientCompany",
      required: true,
      disabled: false || !isCreateNewContact,
      isVisible: true,
    },
    {
      label: "Client Phone Number",
      key: "phoneNumber",
      required: true,
      disabled: false || !isCreateNewContact,
      isVisible: true,
    },
    {
      label: "Client Email Address",
      key: "email",
      required: true,
      disabled: false || !isCreateNewContact,
      isVisible: true,
      isMatch:true
    },
  ];

  const handleInputChange = useCallback(
    (key: keyof ClientInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const reg = /^[0-9\b]+$/;
      if (key === "phoneNumber") {
        if (reg.test(value) || value === "")
          dispatch(setClientInfo({ ...clientInfo, [key]: value }));
      } else if(key === "firstName" ||key === "lastName"){
        if(onlyAlphabet(value)){
          dispatch(setClientInfo({ ...clientInfo, [key]: value }));
        }
      }
  
       else {
        dispatch(setClientInfo({ ...clientInfo, [key]: value }));
      }
    },
    [dispatch, clientInfo]
  );
  useEffect(() => {
    const data: any = {
      clientId: "",
      clientName: "",
      clientTitle: "",
      email: "",
      phoneNumber: "",
      clientCompany: "",
    };
    dispatch(setClientInfo(data));
  }, [isCreateNewContact]);
  const handleComboBoxChange = (selectedOption: any) => {
    const updatedClientInfo = {
      ...clientInfo,
      clientName: selectedOption ? selectedOption.label : "",
      clientTitle: selectedOption ? selectedOption.title : "",
      email: selectedOption ? selectedOption.emailAddress : "",
      clientId:
        selectedOption && !selectedOption.__isNew__ ? selectedOption.id : "",
      phoneNumber: selectedOption ? selectedOption.phoneNumber : "",
      clientCompany: selectedOption ? selectedOption.clientCompany : "",
    };

    dispatch(setClientInfo(updatedClientInfo));
  };

  const fetchClients = () => {
    const payload: any = {
      data: clientInfo.clientName || "",
      pageNo: 0,
      pageSize: 10000,
     "sortBy":"lastName",
     "orderBy":"ASC"
    };
    dispatchForClient(getClientList(payload));
  };

  useEffect(() => {
    fetchClients();
  }, [dispatchForClient, clientInfo.clientName, testClientName]);

  const getClientListOption = () => {
    const list: Array<any> = clients?.data?.map((cl?: any) => ({
      label: cl.firstName + " " + cl.middleName + " " + cl.lastName,
      value: cl.firstName + " " + cl.middleName + " " + cl.lastName,
      phoneNumber: cl.phoneNumber,
      id: cl.id,
      title: cl.salutation,
      emailAddress: cl.emailAddress,
      clientCompany: cl.clientCompany,
    }));

    return list;
  };

  return (
    <>
      <div>
        <span className="font-bold text-[#045373]">Client Information</span>
      </div>
      {/* <div className="mt-2 flex flex-col gap-1"></div> */}
      <div className=" flex flex-col gap-1 relative ">
        <span
          onClick={() => setIsCreateNewContact(!isCreateNewContact)}
          className="text-xs text-blue-500 text-right z-20 absolute right-0 -top-1 cursor-pointer underline"
        >
          {!isCreateNewContact ? "Create New" : "Use Existing"}
        </span>

        <ComboBox
          label={"Client Name"}
          options={getClientListOption()}
          value={
            clientInfo.clientId
              ? { label: clientInfo.clientName, value: clientInfo.clientId }
              : { label: clientInfo.clientName, value: "" }
          }
          onChange={handleComboBoxChange}
          onInputChange={(e) => {
            setTestClientName(e);
          }}
          required={false}
          isShowCreate={isCreateNewContact}
        />
      </div>

      {clientInputs.map(
        ({ label, key, required, disabled, isVisible }) =>
          isVisible && (
            <div key={label} className="mt-2 flex flex-col gap-1">
              <FormInput
                name={label}
                label={label}
                required={required}
                type="text"
                className={`border-2 text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent h-[34px] ${disabled ? "!border-2 !border-[#CECECE] !border-t-[#CECECE]" : "border-[#CECECE] border-t-[#CECECE] bg-white"}`}
                maxLength={key === "phoneNumber" ? 10 : 100}
                value={clientInfo?.[key] || ""}
                onChange={handleInputChange(key)}
                disabled={disabled}
                
              />
             {key=="email"&& !emailValidation(clientInfo?.["email"]) && <span className="text-red-700 text-xs"> Enter valid email </span>}
            
            </div>
          )
      )}
    </>
  );
};

export default ClientInformation;
