import React from 'react'

import PageNotFopundIMg from '../../assets/404_page-not-found.png'
export default function PageNotFound() {
  return (
    <div className='flex justify-center align-middle '>
        {/* <p >Page Not Found</p> */}
<img src={PageNotFopundIMg} />
        </div>
  )
}
