/* eslint-disable jsx-a11y/alt-text */
import { GridColDef } from "@mui/x-data-grid";
import Claim from "./Claim";
import reportingIcon from "../../assets/images/initate_reporting.png"
import { Tooltip } from "@material-tailwind/react";

const columnDefs = (onClaimClick: (claim: Claim) => void , onInitiatReporting :(claim:Claim)=>void): GridColDef<Claim>[] => [
    {
        field: "fileNumber",    
        headerName: "Ampcus File Number",
        flex:1,
        sortable: true,
        renderCell: (params) => (
            <div>
                <span className="cursor-pointer">{params.value}</span>
            </div>
        ),
    },
    {
        field: "claimNumber",
        headerName: "Claim Number",
        flex:1,
        sortable: true,
        renderCell: (params) => (
            <div onClick={() => onClaimClick(params.row)}>
                <span className="text-[#0b8ce8] underline cursor-pointer">{params.value}</span>
            </div>
        ),
    },
    { field: "clientName", headerName: "Client Name",  flex:1,sortable: true,
        renderCell: (params:any) => (
        <div onClick={() => console.log(params.row)}>
            <span className="">{params.row?.clientDetails?.firstName} {params.row?.clientDetails?.lastName}</span>
        </div>
    ), },
    { field: "insuredBusinessName", headerName: "Insured Business Name", width: 180,sortable: true },
    {
        field: "assignee",
        headerName: "Assigned Accountant",
        flex:1,
        sortable: true
    },
    {
        field: "status",
        headerName: "Status",
        flex:1,
        sortable: true,
        renderCell: (params:any) => (
            // <span className={`status-text ${params.value === "Active" ? "text-[#008000]" : params.value === "Pending" ? "text-[#ffa500]" : "text-[#ff0000]"}`}>
            <span className="font-bold">    
            {params.row?.workItem?.WorkStatus||"-"}
            </span>
        ),
    },
    {
        field: "Initiate Report",
        headerName: "Initiate Report",
        flex:1,
        sortable: true,
        renderCell: (params:any) => (
           
            <img src={reportingIcon} onClick={() => onInitiatReporting(params.row)} width={"20px"} className="mt-3 cursor-pointer"/>
     
            // <span className={`status-text ${params.value === "Active" ? "text-[#008000]" : params.value === "Pending" ? "text-[#ffa500]" : "text-[#ff0000]"}`}>
        //    <CustomButton   style={{width:100}}
        //    className={cx(
        //         "bg-[#1F7FBB]",
        //         "border-[#1F7FBB]",
        //         "mt-3",

        //         "!w-auto",
        //           "h-[30px]"
        //       )}
        //       label="Intiate Reporting"
        //       onClick={() => onInitiatReporting(params.row)}
        //      />
        ),
    },
];

export default columnDefs;
