/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import botImg from "../../assets/images/chat.png";
import arrow from "../../assets/images/arrow-down.gif";
import handIcon from "../../assets/images/hand-icon.png";
import { Button } from "@material-tailwind/react";
import sign from "../../assets/images/zigzag.png";
import "../../css/chatbot.css";
import {
  ArrowRightIcon,
  EnvelopeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import Privacy from "../../assets/images/privacy.png";
import FormInput from "../../components/FormInput";
import { emailValidation } from "src/utils";
import { LANGUAGE } from "src/utils/constant";
import Select from 'react-select';
interface PropsType {
  onClick: () => void;
  disableButton: boolean;
  siteData: urlDecodedDataProps;
  language:any;
  setLanguage:(e?:any)=>void;
}
export default function AwsWelcome({
  onClick,
  disableButton,
  siteData,
  setLanguage,
  language
}: PropsType) {
  const [isContinue, setIsCoutinue] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isError, setError] = useState<boolean>(true);
 

  useEffect(() => {
    if (siteData) {
      setEmail(siteData.email);
      setName(siteData.name);
    }
  }, [siteData]);

  useEffect(() => {
    console.log(emailValidation(email));
    if (name && email && name?.trim() && email?.trim()) {
      if (!emailValidation(email)) {
        setError(true);
      } else {
        setError(false);
      }
    } else {
      setError(true);
    }
  }, [name, email]);

  const _checkUserInfo = () => {
    if (email && name) {
      onClick();
    }
  };

  return (
    <div className="border-[#DDD] w-full rounded-sm pb-5 h-[75vh] mt-6 pl-7">
      <div className="flex w-full h-full ">
        <div className="w-1/2 justify-center content-center  lg:block md:block">
          <div className="spangrey  lg:w-1/2 md:w-1/2 sm:w-full sm:!text-[14px] lg:!text-[18px] md:!text-[18px] ">
            <span className="flex  items-center">
              <span className="text-xl whitespace-nowrap flex">
                {" "}
                Chat with Eli’ mode-{" "}
                <div className="rounded-full bg-[#0774D9] h-2 w-2 m-auto ml-2"></div>
              </span>
            </span>
          </div>
          <span className="flex  items-center pt-4 ">
            <span className="flex">
              {" "}
              Hello &nbsp; <p className="font-semibold">{name}</p> ! &nbsp;{" "}
            </span>
            <img className="h-8 w-8 mt-1 " src={handIcon} alt="bot image" />
          </span>
          <span className="">
            Meet Eli, Eli is here to assist you in providing the details 
            of your business loss. Through a series of simple prompts, 
            Eli will guide you step-by-step to ensure all the necessary information for your claim is captured accurately. 
            Let's begin!
          </span>
          <img className="h-6 md:h-8 relative left-[50%] mt-2" src={sign} />
          <p className="text-[#1F7FBB] text-xl mb-8 pt-4 font-semibold">
            Fill Your Information
          </p>
          <FormInput
            icon={<EnvelopeIcon />}
            className="bg-white"
            value={email}
            label="Email"
            required
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <label className={`text-xs font-medium mb-1 `}>
            {"Preferred Language"}
            <span className="text-red-500"> *</span>
          </label>
          <select
            className="p-0 pl-[8px] pr-[8px] text-sm text-blue-gray-700 w-full h-[38px] border-2 rounded-md border-[#CECECE] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 focus:border-1 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10"
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          >
            {LANGUAGE.map((item: any, key: number) => (
              <option value={item.value} key={key}>
                {/* {item.icon} */}
                {item.name}
              </option>
            ))}
          </select>
      
          <Button
            onClick={_checkUserInfo}
            disabled={isError}
            style={{
              flex: 1,
              // backgroundColor: "#207FBB",
              color: "#FFF",
              marginTop: 60,
              paddingLeft: 40,
              paddingRight: 40,
            }}
            className=" flex get__started__btn items-center gap-3 text-[14px]  "
          >
            Continue
            <ArrowRightIcon color="#FFF" width={25} height={25} />
          </Button>
        </div>

        {
          <div className="h-[80vh] lg:w-1/2   :w-1/2 sm:w-[800px] p-4 pl-7  ">
            <img
              className="h-3/4 w-3/4 object-cover object-center self-center m-auto"
              src={botImg}
              alt="bot image"
            />
          </div>
        }
      </div>
    </div>
  );
}
