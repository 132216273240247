/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import bgline from "../../../assets/images/bg_line.png";
import sign from "../../../assets/images/sign.png";
import ScheduleTabs from "./scheduleTabs";
import "./schedule.css";
import info from "../../../assets/images/info.png";
import arrowBack from "../../../assets/images/Left Arrow.png";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
interface Props {
  setActiveTab: (e?: string) => void;
  activeTab: any;
}
const InitialPage = ({ setActiveTab, activeTab }: Props) => {
  const [step, setStep] = useState<any>(1);
  return (
    <div className="flex flex-col justify-center items-center relative ">
      <img className="absolute top-[-200px] left-[10] w-full " src={bgline} />
      {step === 1 ? (
        <div className="mt-10">
          <h1 className="font-[700] lg:text-[50px] xs:text-[50px] md:text-[50px] leading-[25px] text-center">
            Claim Interviews
          </h1>
          <div className="sliding-vertical-new relative flex justify-center lg:h-54 xs:h-[8rem] md:h-[8rem]">
            <span>Simplified.</span>
            <span>Automated.</span>
            <span>Optimized.</span>
            <span>Simplified.</span>
            <span>Automated.</span>
            <span>Optimized.</span>
          </div>
          <img className="h-10 md:h-10 relative left-[18%]" src={sign} />
          <h2 className="text-center font-[400] text-[15px] leading-[26px] md:text-[18px] md:leading-[31px] text-[#656F80] mt-2 pl-[5%] pr-[5%] md:pl-[10%] md:pr-[10%] lg:pl-[20%] lg:pr-[20%]">
          Ampcus Forensics Inc is an accounting firm specializing in the review and analysis of business interruption claims for insurance carriers. Our team ensures thorough
           and accurate evaluations by examining your operations and claim details. We prioritize accuracy, transparency, and efficiency, while accelerating processing times and gathering essential information at every step.
          </h2>
          <div className="flex justify-center mt-8" onClick={() =>{setStep(2)}}>
            <button
              type="button"
              className=" z-[999999] get__started__btn basic__hover  text-white flex justify-center gap-5 items-center !cursor-pointer font-[500] text-[18px] leading-[27px] pl-24 pr-24 h-[58px]"
     >
              Get Started<ArrowRightIcon color="#FFF" width={25} height={25} />
            </button>
          </div>
          {/* <img src ={arrowDown} width={"40px"} className="mt-2"/> */}
          <div className=" border-b-4 border-[#0774D9] h-[20px] w-full rounded-lg shadow-xl mt-8"></div>
          {/* <img src={info} className="w-[90%] relative top-[-25px] mb-40"/> */}
        </div>
      ) : (
        <>
              <div className="container flex flex-col w-full mt-2 mb-2">
              <span className=" flex text-lg text-[#000] font-semibold z-[999999] cursor-pointer"
              onClick={()=>setStep(1)}
              >
            {" "}
            <img src={arrowBack} width={"40px"} /> Back</span>
          </div>
          <ScheduleTabs setActiveTab={setActiveTab} activeTab={activeTab} />
        </>
      )}
    </div>
  );
};

export default InitialPage;
