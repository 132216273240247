import axios from "axios";
import BASE_URL from "./ApiUrl";

const axiosInstance = axios.create({
  baseURL:BASE_URL.defaultUrl,
  headers: {
    // "Content-Type": "application/json",
  },
});

export default axiosInstance;
