/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import moment from "moment";

function Icon(id: any, open: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}
interface props{
  categoryRes?:any;
  questionsRes?:any;
  anwserSet?:any;
}


const AnswerDisplay: React.FC<{Id: any,anwserSet:any }> = ({ Id ,anwserSet}) => {
  // Use the `find` method to locate the user
  const answer = anwserSet.find((ans:any) => ans.questionId === Id);
  const answers = anwserSet.filter((ans:any) => ans.questionId === Id && ans.multi_chip_selection_text!=undefined)
  return (
    <label className="font-poppins text-[12px] antialiased not-italic font-normal mt-1">
        {answer?.free_form_text}  
        {answer?.mcq_text}   
        {answer?.multi_choice_text}  
        {answer?.date_text? moment(answer?.date_text).format("YYYY-MM-DD"):""}   
        {answer?.file_url_text}  
        {answer?.rating_text}   
        {answer?.chip_selection_text}  
        {answer?.free_for_large_text} 
        {answers?.map((ans:any,index:any)=><span>{ans.multi_chip_selection_text}{index<answers.length-1?", ":""}</span>)}


    
    </label>
  );
};

export function ReviewSection({categoryRes,questionsRes,anwserSet}:props) {
  const [open, setOpen] = React.useState(0);
  const handleOpen = (value?: any) => setOpen(open === value ? 0 : value);
  console.log(questionsRes)
  return (
    <div className="max-h-[85%] overflow-auto ">
      {categoryRes?.map((item?: any, index?: number) => {
        return (
         (
            <Accordion
            className={"mb-2 rounded-md"}
              open={open === index}
              icon={<Icon id={index} key={index} open={open} />}
            >
              <AccordionHeader
                onClick={() => handleOpen(index)}
                className="text-[#000] bg-[#D9D9D9] p-4 h-10 text-base"
              >
                {item.categoryName}
              </AccordionHeader>
              <AccordionBody>
                <div className="w-[96%] grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-4 mx-4 my-4 ">
                  {questionsRes?.map((dt?: any, key?: number) => (
                  item.categoryName === dt.category &&<> <div key={key} className="flex flex-col">
                      <label className="font-poppins text-sm text-[#000] antialiased not-italic font-medium">
                        {dt.question}
                      </label>
                      <AnswerDisplay anwserSet={anwserSet} Id={dt.id}/>
                    </div>
                    {dt?.options?.map((opt: any) =>  <>
                      {anwserSet?.filter((obj2:any) =>
                          dt.id === obj2.questionId &&
                          opt.option === obj2.mcq_text
                      ).length>0 ?
                      
                      opt?.hierarchicalQuestions?.map((hr:any)=>{
                        return <div className="flex flex-col"><span className="text-[14px] font-semibold mb-1">
                                
                      {hr.question}
                      
                     </span>
                     <AnswerDisplay anwserSet={anwserSet} Id={hr.id}/>
                        </div>

                     
                       }):anwserSet?.filter((obj2:any) =>
                        opt.id === obj2.questionId &&
                        opt.option === obj2.multi_choice_text
                    ).length>0 ? opt?.hierarchicalQuestions?.map((hr:any)=>{
                      return <div className="flex flex-col">  <span className="text-[14px] font-semibol mb-1 flex-col">
                     {hr.question}
                    </span>
                    <AnswerDisplay anwserSet={anwserSet} Id={hr.id}/>
                      </div>
                  }):null}
                    </>
                  )}
                    
                    </>

                  ))}
                </div>
              </AccordionBody>
            </Accordion>
          )
        );
      })}
    </div>
  );
}
