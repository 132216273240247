/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  attemptInterview,
  scheduleInterview,
  getScheduleList,
} from "src/api/interview";
import { editSchedule, deleteSchedule } from "../../api/interview";

interface clientState {
  loading: boolean;
  error: boolean;
  message: any;
  interviewScheduledRes: any;
  attemptedInterviewRes: any;
  scheduleListLoading: boolean;
  scheduleListError: boolean;
  scheduleListMessage: any;
  getScheduleListData: any;
  //edit schedule
  editScheduleRes: any;
  editScheduleError: boolean;
  //delete schedule
  deleteScheduleRes: any;
  deleteScheduleError: boolean;
}
const initialState: clientState = {
  loading: false,
  error: false,
  message: "",
  interviewScheduledRes: null,
  attemptedInterviewRes: null,
  scheduleListLoading: false,
  scheduleListError: false,
  scheduleListMessage: "",
  getScheduleListData: null,
  //edit schedule
  editScheduleRes: null,
  editScheduleError: false,
  //delete schedule
  deleteScheduleRes: null,
  deleteScheduleError: false,
};
const interviewSlice = createSlice({
  name: "Interviews",
  initialState,
  reducers: {
    resetDeleteSchedulingValue: (state) => {
        state.deleteScheduleRes= null
        state.deleteScheduleError= false
        state.editScheduleRes= null
        state.editScheduleError= false
    
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(scheduleInterview.pending, (state) => {
        state.loading = true;
        state.message = "";
        state.error = false;
      })
      .addCase(scheduleInterview.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = false;
      })
      .addCase(scheduleInterview.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = true;
        state.interviewScheduledRes = null;
      })
      .addCase(attemptInterview.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message = "";
      })
      .addCase(attemptInterview.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        // state.message=action.payload?.message
      })
      .addCase(attemptInterview.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
      })
      .addCase(getScheduleList.pending, (state) => {
        state.scheduleListLoading = true;
        state.scheduleListError = false;
        state.scheduleListMessage = "";
        state.getScheduleListData = null;
      })
      .addCase(getScheduleList.fulfilled, (state, action) => {
        state.scheduleListLoading = false;
        state.scheduleListError = false;
        state.scheduleListMessage = action.payload?.message;
        state.getScheduleListData = action.payload?.data;
      })
      .addCase(getScheduleList.rejected, (state, action) => {
        state.scheduleListLoading = false;
        state.scheduleListError = true;
        state.scheduleListMessage = action.payload;
        state.getScheduleListData = null;
      })

      .addCase(editSchedule.pending, (state) => {
        state.editScheduleError = false;
        state.editScheduleRes = null;
      })
      .addCase(editSchedule.fulfilled, (state, action) => {
        state.editScheduleError = false;
        state.editScheduleRes = action.payload;
      })
      .addCase(editSchedule.rejected, (state, action) => {
        state.editScheduleError = true;
        state.editScheduleRes = action.payload;
      })
      .addCase(deleteSchedule.pending, (state,) => {
        state.deleteScheduleError = false;
        state.deleteScheduleRes = null;
      })
      .addCase(deleteSchedule.fulfilled, (state, action) => {
        state.deleteScheduleError = false;
        state.deleteScheduleRes = action.payload;
      })
      .addCase(deleteSchedule.rejected, (state, action) => {
        state.deleteScheduleError = true;
        state.deleteScheduleRes = action.payload;
      });
  },
});
export const {
    resetDeleteSchedulingValue
} = interviewSlice.actions;
export default interviewSlice.reducer;
